<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-autocomplete
    :dark="themeDark"
    :dense="!AppIsSmall"
    :hide-no-data="hideNoData"
    :items="items"
    :label="$t('hints.global_search')"
    :light="themeLight"
    :loading="isLoading"
    :menu-props="menuProps"
    :placeholder="$t('hints.global_search')"
    :search-input.sync="search"
    append-icon=""
    disable-lookup
    flat
    hide-details
    hide-selected
    prepend-inner-icon="search"
    solo
    v-bind="$attrs"
    @blur="hideNoData = !0"
    v-on="$listeners"
  >
    <template #item="{item}">
      <v-list-item :to="route(APP_ROUTES.course.mainShow,{id:item.id})">
        <v-list-item-avatar>
          <img
            :src="item.avatar"
            alt=""
          >
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="item.course_name" />
          <!--<v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>

import _ from 'lodash'

export default {
  name: 'SearchBar',
  props: {},
  data: () => ({
    dialog: !0,
    hideNoData: !0,
    isLoading: false,
    model: null,
    items: [],
    search: null,
    menuProps: {
      closeOnClick: false,
      closeOnContentClick: true,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 300,
      offsetY: true,
      offsetOverflow: true,
      transition: false
    }
  }),

  computed: {},

  watch: {
    search: {
      deep: !0,
      handler: _.debounce(function (search) {
        this.onSearch(search)
      }, 600)
    }
  },
  methods: {
    onSearch (search) {
      this.hideNoData = !0
      this.items = []
      if (this.isLoading || !search || search.length < 3) return

      this.isLoading = !0
      // console.log(search)

      this.apiService.utilities.search(search).then(res => {
        const { _data } = res
        this.items = _data || []
        this.hideNoData = this.items.length > 0
        // console.log(_data)
      }).catch(err => {
        // console.log(err)
      }).finally(() => (this.isLoading = !1))
    }
  }
}
</script>
