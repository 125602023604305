/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@config'
import { locales, messages } from './register'

VueI18n.availableLocales = locales

Vue.use(VueI18n)

const options = {
  locale: config.locale.defaultLocale,
  fallbackLocale: config.locale.defaultLocale,
  messages
}
const i18n = new VueI18n(options)

export default i18n
