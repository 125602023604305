/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    themeDark: {
      get () {
        return Boolean(mapGetters('auth', ['themeDark']).themeDark.call(this))
      },
      set (value) {
        this.$vuetify.theme.dark = value
        // this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, { value })
      }
    },
    themeLight: {
      get () {
        return !this.themeDark
      },
      set (value) {
        value = !value
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, { value })
      }
    },
    themeMode () {
      return this.themeDark ? 'dark' : 'light'
    },
    themeModeReverse () {
      return !this.themeDark ? 'dark' : 'light'
    },
    themeVariation () {
      return this.themeDark ? 'darken' : 'lighten'
    },
    themeVariationReverse () {
      return !this.themeDark ? 'darken' : 'lighten'
    }
  }
}
