/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import config from '@/app/config'
import { localize } from '@plugins/vee-validate'
import { locales } from '@plugins/vuetify-vue-i18n/register'
import _, { camelCase, isString, snakeCase } from 'lodash'
import { APP_ROUTES } from '@routes/list'
import axios from 'axios'

const defaultLocale = config.locale.defaultLocale
const LOCALE_STORAGE_KEY = config.locale.storageKey

export default {
  data: () => ({
    APP_DEBUG: process.env.NODE_ENV === 'development',
    APP_ROUTES,
    COL_SIZES: {
      cols: 12,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      xl: 2
    }
  }),
  methods: {
    IsAppLocale (locale) {
      return Boolean(this.AppLocales.find(l => l.code === locale))
    },
    async SetAppLocale (locale, reload = !0) {
      this.AppLocale = locale
      if (this.authUser) {
        try {
          await this.apiService.user.updateLocale(locale)
        } catch (e) {

        }
      }
      reload === !0 && this.$nextTick(() => window.location.reload())
    },
    IniAppLocale () {
      this.AppLocale = localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLocale
    },

    getPageTitle (number = 2) {
      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const e = (...a) => this.$te(...a)
      // const _ = this.$_
      let str
      if (!this.$route?.name || !this.$route?.path) {
        return str
      }
      const { name, pageName } = this.$options || {}
      const routePath = this.$route.path.split('/').pop()
      const routeName = this.$route.name.split('/').pop()
      // const pluralize = _.pluralize(_.upperFirst(_.trimEnd(routeName.split('.').pop().split('Index').join('').split('index').join(''),'/')))
      let f = routeName.split('.Index').join('').split('.index').join('').split('.').pop()
      f = _.camelCase(f)
      const pluralize = _.pluralize(_.upperFirst(f))
      // console.log(pluralize)
      const localFile = `${_.camelCase(routeName.split('.').pop())}Page`
      // console.log(routeName)
      let keys = [
        `$routes.${this.$route.name}`,
        `$routes.${routePath}`,
        `$routes.${camelCase(routePath)}`,
        // routePath,
        // camelCase(routePath),
        `${camelCase(routePath)}Page.name`,

        `$routes.${routeName}`,
        `$routes.${camelCase(routeName)}`,
        // routeName,
        // camelCase(routeName),
        `${camelCase(routeName)}Page.name`,
        `${localFile}.name`,

        `choice.${pluralize}`,
        `attributes.${snakeCase(routeName)}`,
        routeName,
        // name,
        camelCase(name),
        pageName
        // camelCase(pageName),
      ]
      keys = _.filter(_.uniq(keys))
      // console.log (e(`${camelCase (routeName)}Page.name`))
      // console.log(routeName)
      // console.log(`attributes.${snakeCase(routeName)}`)
      let k
      for (const f in keys) {
        // console.log(k)
        if (!(k = keys[f])) {
          continue
        }
        // console.log(t(k))
        // continue
        if (e(k) && isString((str = tc(k, number)))) {
          return str
        }
        if (e(k) && isString((str = t(k)))) {
          return str
        }
      }
      return undefined
    },
    parseAttribute (string, ...args) {
      if (!string) return string

      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const te = (...a) => this.$te(...a)

      const key = (this.$helpers.isOnlyObject(string) ? (string.text || '') : string).toString()

      if (te(`attributes.${key}`) && isString(t(`attributes.${key}`))) {
        return t(`attributes.${key}`, ...args)
      }
      if (te(`choice.${key}`) && isString(t(`choice.${key}`))) {
        args = args || []
        if (!args[0]) {
          args[0] = 2
        }
        return tc(`choice.${key}`, ...args)
      }

      if (te(key) && isString(t(key))) {
        return t(key, ...args)
      }

      return string
    },

    /**
     *
     * Example: الغرب => غرب
     * @param string
     * @param locale
     * @returns {*}
     */
    parseArabicTitle (string, locale) {
      locale = locale || this.AppLocale
      if (string && locale === 'ar') {
        let i
        const e = string.split(' ')
        for (i in e) {
          if (e[i].substr(0, 2) === 'ال') {
            e[i] = e[i].substr(2)
          }
        }
        string = e.join(' ')
      }
      return string
    },
    /**
     * Example: غرب => الغرب
     * @param string
     * @param locale
     * @returns {*}
     */
    parseArabicReplace (string, locale) {
      locale = locale || this.AppLocale
      if (string && locale === 'ar') {
        let i
        const e = string.split(' ')
        if (e[e.length - 1].substr(0, 2) === 'ال') {
          return string
        }
        for (i in e) {
          if (e[i].substr(0, 2) !== 'ال') {
            e[i] = 'ال' + e[i]
          }
        }
        string = e.join(' ')
      }
      return string
    },
    /**
     * Example: غرب => 'الغربي'
     * @param string
     * @param locale
     * @returns {*}
     */
    parseEndArabicReplace (string, locale) {
      locale = locale || this.AppLocale
      if (string && locale === 'ar') {
        let i
        const e = string.split(' ')
        for (i in e) {
          if (e[i].substr(0, 2) !== 'ال') {
            e[i] = 'ال' + e[i]
          }
          if (e[i].substr(-1) !== 'ي') {
            e[i] += 'ي'
          }
        }
        string = e.join(' ')
      }
      return string
    },

    doAxiosWithErrors (func, val) {
      const temp = this.$root.RootShowAxiosErrorMessage
      this.$root.RootShowAxiosErrorMessage = val
      func && func()
      this.$root.RootShowAxiosErrorMessage = temp
    },
    hideAxiosErrors (func) {
      if (func) {
        this.doAxiosWithErrors(func, !1)
      } else {
        this.$root.RootShowAxiosErrorMessage = !1
      }
    },
    showAxiosErrors (func) {
      if (func) {
        this.doAxiosWithErrors(func, !0)
      } else {
        this.$root.RootShowAxiosErrorMessage = !0
      }
    },

    copyText (text, parent) {
      parent = parent || document.body
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed'
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.zIndex = '909090'
      parent.appendChild(textArea)
      textArea.focus()
      textArea.select()
      textArea.setSelectionRange(0, 99999)
      let success = !1
      try {
        success = document.execCommand('copy')
        // console.log(success)
      } catch (err) {
        success = !1
        console.error('Fallback: Oops, unable to copy', err)
      }
      parent.removeChild(textArea)
      return success
    },

    goToBack (name) {
      if (window?.history.length > 2) {
        this.$router.back()
        return
      }
      this.navigate(name)
    },

    downloadFromResponse (response) {
      return this.$helpers.downloadFromResponse(response, this)
    },
    openWindow (url, ...args) {
      if (this.IsMobileApp) {
        url && this.PostAppEvent({
          action: 'window.open',
          url
        })
        // alert(url)
      } else {
        window.open(url, 'AppWindow', ...args)
      }
    },
    bottomVisible () {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = ((visible + scrollY) >= pageHeight)
      return Boolean(bottomOfPage || (pageHeight < visible))
    },
    PostAppEvent (args) {
      if (window?.ReactNativeWebView) {
        try {
          const data = args ? JSON.stringify(args) : {}
          window.ReactNativeWebView.postMessage(data)
        } catch (e) {
          alert('Error PostAppEvent')
        }
      }
    },
    AppConsole (...args) {
      // console.log(...args)
      if (window?.ReactNativeWebView) {
        try {
          const data = {
            action: 'console.log',
            args
          }
          const string = JSON.stringify(data)
          window.ReactNativeWebView.postMessage(string)
          // alert(string)
        } catch (e) {
          alert('Error AppConsole')
        }
      }
    }
  },
  computed: {
    AppRtl: {
      set (v) {
        this.$root.$vuetify.rtl = v
      },
      get () {
        const { rtl } = this.$root.$vuetify || {}
        return rtl !== undefined ? rtl : true
      }
    },
    AppDirection () {
      return this.AppRtl ? 'rtl' : 'ltr'
    },
    AppAlign () {
      return this.AppRtl ? 'right' : 'left'
    },
    AppAlignReverse () {
      return this.AppRtl ? 'left' : 'right'
    },
    AppLocales () {
      return locales.map(code => ({ title: this.$t(code), code }))
    },
    AppLocale: {
      set (locale = defaultLocale) {
        locale = ('' + locale).toString().toLocaleLowerCase()

        !this.IsAppLocale(locale) && (locale = defaultLocale)

        localStorage.setItem(LOCALE_STORAGE_KEY, locale)
        document.documentElement.setAttribute('lang', locale)
        document.documentElement.setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

        // Vuetify
        this.$root.$vuetify.lang.current = locale
        this.$root.$vuetify.rtl = locale === 'ar'

        // i18n
        this.$root.$i18n.locale = locale

        // axios
        axios.defaults.headers.common['App-Locale'] = locale

        // Vee-validate
        const validations = require(`@locales/${locale}/validations.js`)
        localize(locale, validations.default || validations)

        // moment js
        this.$moment.locale(locale)
        // console.log(locale)
      },
      get () {
        return this.$root.$vuetify.lang.current
      }
    },
    AppHeight () {
      return this.$vuetify.breakpoint.height - this.$vuetify.application.top
    },
    apiService () {
      return this.$api.methods
    },

    AppIsSmall () {
      return this.$vuetify.breakpoint.mobile
      // return this.$vuetify.breakpoint.xsOnly
    },
    IsMobileApp () {
      return window.isMyThApp
    },
    logoSize () {
      return this.AppIsSmall ? 100 : 150
    },
    logoColor () {
      return undefined
      // return this.themeDark ? 'gray' : 'white'
    },
    copyright () {
      return this.$t('copyright', { date: (new Date()).getFullYear(), name: this.AppName })
    },

    avatarColor () {
      return 'grey ' + (this.themeLight ? 'lighten-3' : 'darken-3')
    },

    SKELETON_COLS () {
      const cols = this.COL_SIZES[this.$vuetify.breakpoint.name] || 12
      return 24 / cols
    },

    landingBarTabs () {
      return [
        {
          name: APP_ROUTES.homePage,
          title: this.parseAttribute('home'),
          to: {
            name: APP_ROUTES.homePage,
            hash: '#home'
          }
        },
        {
          name: APP_ROUTES.homePage,
          title: this.parseAttribute('about_us'),
          to: {
            name: APP_ROUTES.homePage,
            hash: '#about_us'
          }
        },
        {
          name: APP_ROUTES.homePage,
          title: this.parseAttribute('services'),
          to: {
            name: APP_ROUTES.homePage,
            hash: '#services'
          }
        }
      ]
    }
  }
}
