/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  title: 'Forgot password',
  subtitle: 'Enter your username to send the verification code',
  notSent: 'Didn\'t get the code?',
  sendAgain: 'Send again',
  codeTitle: 'Password reset verification code',
  resetPasswordSubtitle: 'Enter the new password',
  resetPasswordPlaceholder: 'A password reset code has been sent to you.',
  resendSpan: 'You can resend the code after: '
}
