/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import colors from '@app/colors'

export const theme = {
  storageKey: 'theme_dark_storage',
  rtl: !0,
  dark: !1,
  themes: {
    light: {
      ...colors
    },
    dark: {
      ...colors
    }
  }
}

export const locale = {
  defaultLocale: 'ar',
  storageKey: 'locale_storage'
}

export const GOOGLE = {
  mapKey: '',
  defaultConfig: {
    zoom: 17,
    gestureHandling: 'cooperative'
  },
  libraries: [
    'visualization',
    'places'
  ].join(','),
  reCaptchaKey: ''
}

export const currency = {
  value: {
    code: 'SAR',
    short_name: 'ريال',
    balance: 1.0
  },
  storageKey: 'currency_storage'
}

export default { theme, locale, currency }
