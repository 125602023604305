<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-list
    :dense="!AppIsSmall"
    class="pa-0"
    flat
    tile
  >
    <template v-for="(item, i) in items">
      <!--<v-divider-->
      <!--  v-if="i===0"-->
      <!--  :key="`top-list-item-divider-${i.toString()}`"-->
      <!--/>-->
      <app-list-item
        :key="`list-item-${i.toString()}`"
        :item="item"
      />
      <!--<v-divider-->
      <!--  :key="`list-item-divider-${i.toString()}`"-->
      <!--/>-->
    </template>
    <slot />
  </v-list>
</template>

<script>

export default {
  name: 'DrawerList',
  props: {
    items: {
      required: !0,
      type: [Array, Object],
      default: () => ([])
    }
  }
}
</script>
