/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  user_id: 'User',
  role_id: 'Role',
  roles_id: 'Roles',
  permission_id: 'Permission',
  permissions_id: 'Permissions',
  country_id: 'Country',
  countries_id: 'Countries',
  city_id: 'City',
  cities_id: 'Cities',
  order_id: 'Order',
  order_no: 'Order No.',
  orders_id: 'Orders',
  order_status_id: 'Order Status',
  order_statuses_id: 'Order Statuses',
  category_id: 'Category',
  categories_id: 'Categories',
  offer_id: 'Offer',
  offers_id: 'Offers',
  order_category_id: 'Order Category',
  order_categories_id: 'Order Categories',
  customer_id: 'Customer',
  provider_id: 'Provider',
  id: 'ID',
  login_id: 'Login ID',
  username: 'Username',
  name: 'Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  password: 'Password',
  password_confirmation: 'Confirm Password',
  mobile: 'Mobile',
  email: 'Email',
  locale: 'Locale',
  active: 'Active',
  code: 'Code',
  send_time: 'Send Time',
  token: 'Token',
  read_at: 'Read at',
  key: 'Key',
  order_by: 'Order by',
  app_name: 'App Name',
  app_name_ar: 'Arabic App Name',
  app_name_en: 'English App Name',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  job_title: 'Job Title',
  company: 'Company',
  company_id: 'Company',
  country: 'Country',
  city: 'City',
  phone: 'Phone',
  whatsapp: 'Whatsapp',
  two_factor_auth: 'Two Factor Auth',
  time: 'Time',
  from_time: 'From Time',
  to_time: 'To Time',
  date: 'Date',
  from_date: 'From date',
  to_date: 'To date',
  type: 'Type',
  status: 'Status',
  push_token: 'Device push token',
  verification_code: 'Verification Code',
  attachment: 'Attachment',
  attachments: 'Attachments',
  notification_email: 'Email notifications',
  notification_mobile: 'Mobile notifications',
  web: 'Website url',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'Customer service email',
  customer_service_mobile: 'Customer service mobile',
  customer_service_phone: 'Customer service phone',
  google_map_url: 'google map url',
  app_store_url: 'App Store url',
  play_store_url: 'Play Store url',
  address: 'Address',
  address_ar: 'Arabic address',
  address_en: 'English address',
  contact_us: 'Contact us',
  contact_us_ar: 'Arabic Contact us',
  contact_us_en: 'English Contact us',
  about_us: 'About us',
  about_us_ar: 'Arabic About us',
  about_us_en: 'English About us',
  privacy_policy: 'Privacy & Policy',
  privacy_policy_ar: 'Arabic Privacy & Policy',
  privacy_policy_en: 'English Privacy & Policy',
  terms_and_conditions: 'Terms & Conditions',
  terms_and_conditions_ar: 'Arabic Terms & Conditions',
  terms_and_conditions_en: 'English Terms & Conditions',
  commercial_register: 'Commercial Register',
  commercial_name: 'Commercial name',
  commercial_name_ar: 'Arabic Commercial name',
  commercial_name_en: 'English Commercial name',
  vat_number: 'VAT number',
  notification_methods: 'Notification Methods',
  avatar: 'Avatar',
  blobAvatar: 'Avatar',
  created_at: 'Created date',
  from_created_at: 'From Created At',
  to_created_at: 'To Created At',
  message: 'Your message',
  price: 'Price',
  notes: 'Notes',
  icon: 'Icon',
  subject: 'Subject',
  image: 'Image',
  images: 'Images',
  choose_image: 'Choose Image',
  update_image: 'Update Image',
  url: 'Url',
  amount: 'Amount',
  discount: 'Discount',
  discount_type: 'Discount Type',
  discount_amount: 'Discount Amount',
  meta: 'Meta',
  quantity: 'Quantity',
  location: 'Location',
  tax: 'TAX',
  new_order_status: 'New Order Status',
  pending_order_status: 'Pending Order Status',
  accepted_order_status: 'Accepted Order Status',
  completed_order_status: 'Completed Order Status',
  canceled_order_status: 'Canceled Order Status',
  fixed_app_fees: 'Fixed App Fees',
  percent_app_fees: 'Percent App Fees',
  payable_id: 'Payable ID',
  payable_type: 'Payable Type',
  total: 'Total',
  data: 'Data',
  from_discount_amount: 'Discount Amount From',
  to_discount_amount: 'Discount Amount To',
  expire_date: 'Expire Date',
  from_expire_date: 'Expire Date From',
  to_expire_date: 'Expire Date To',
  read: 'read',
  ref_key: 'Reference ID',
  attachment_type: 'Attachment Type',
  percentage: 'Percentage',
  vat: 'Vat',
  vat_amount: 'Vat Amount',
  payment_date: 'Payment Date',
  from_payment_date: 'Payment Date From',
  to_payment_date: 'Payment Date To',
  payment_type: 'Payment Type',
  paid_amount: 'Paid Amount',
  color: 'Color',
  symbol: 'Symbol',
  contact: 'Contact',
  start_date: 'Start Date',
  from_start_date: 'From Start_date',
  to_start_date: 'To_start Date',
  end_date: 'End_date',
  from_end_date: 'From End_date',
  to_end_date: 'To_end Date',
  start_time: 'Start Time',
  from_start_time: 'From Start_time',
  to_start_time: 'To_start Time',
  end_time: 'End_time',
  from_end_time: 'From End_time',
  to_end_time: 'To_end Time',
  database: 'App',
  mail: 'E-mail',
  sms: 'SMS',
  pay_date: 'Pay Date',
  from_pay_date: 'From Pay Date',
  to_pay_date: 'To Pay Date',
  due_amount: 'Due Amount',
  customer_name: 'Customer Name',
  signature: 'Signature',
  stamp: 'Stamp',
  other: 'Other',
  latitude: 'Latitude',
  longitude: 'Longitude',
  other_address: 'Other Address',
  order_amount: 'Order Amount',
  order_total: 'Order Total',
  order_details: 'Order Details',
  order_notes: 'Order Notes',
  closed_date: 'Closed Date',
  closed_time: 'Closed Time',
  closed_by_id: 'Closed By',
  cancel_reason: 'Cancel Reason',
  cancel_date: 'Cancel Date',
  cancel_time: 'Cancel Time',
  canceled_by_id: 'Canceled By',
  attachment_type_id: 'Attachment Type',
  attachment_types_id: 'Attachment Types',
  job_position_id: 'Job Position',
  job_positions_id: 'Job Positions',
  bank_id: 'Bank',
  banks_id: 'Banks',
  service_type_id: 'Service Type',
  service_types_id: 'Service Types',
  age: 'Age',
  have_obligations: 'Have Obligations',
  salary: 'Salary',
  owner_information: 'بيانات المالك',
  owner_name: 'اسم المالك',
  owner_mobile: 'جوال المالك',
  down_payment: 'الدفعة الأولى',
  amount_before_variance: 'قيمة العقار قبل التفاوت',
  amount_after_variance: 'قيمة العقار بعد التفاوت',
  finance_amount: 'مبلغ التمويل العقاري',
  owner_check: 'شيك المالك',
  fees_amount: 'قيمة السعي',
  finance_calc_amount: 'حسبة التمويل العقاري',
  real_estate_installment: 'القسط العقاري',
  finance_bank_id: 'بنك التمويل',
  no_user: 'No User',
  follow_up_orders: 'Follow Up Orders',
  hide_notes: 'Hide Notes'
}
