/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */
import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@plugins/vue-router'

Vue.use(VueGtag, {
  config: {
    id: 'G-01FXZ7MFRT',
    params: {
      send_page_view: !0
    }
  },
  appName: '',
  pageTrackerScreenviewEnabled: true
}, router)

export default VueGtag
