/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  title: 'إنشاء حساب جديد',
  subtitle: 'قم بإنشاء حسابك الآن',
  have_account: 'لديك حساب بالفعل؟ '
}
