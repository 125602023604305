/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  Auths: 'Auth|Auths',
  SideMenus: 'Side Menu|Side Menus',
  Ages: '{0}-|[1]One Year|[2,*]:age Years',
  Days: '{0}-|[1]One Day|[2,*]:days Days',
  Minutes: '{0}-|[1]One Minute|[2,*]:minutes Minutes',
  Hours: '{0}-|[1]One Hour|[2,*]:hours Hours',
  Months: '{0}-|[1]One Month|[2,*] :months Month',
  Profiles: 'Profile|Profile',
  Avatars: 'Avatar|Avatar',
  MediaFiles: 'Attachment|Attachments',
  Attachments: 'Attachment|Attachments',
  Signatures: 'Signatures|Signatures',
  Permissions: 'Permission|Permissions',
  Roles: 'Role|Roles',
  Users: 'User|Users',
  Sales: 'Sale|Sales',
  Expenses: 'Expense|Expenses',
  Utilities: 'Utility|Utilities',
  Notifications: 'Notification|Notifications',
  Settings: 'Setting|Settings',
  Countries: 'Country|Countries',
  Cities: 'City|Cities',
  Companies: 'Company|Companies',
  Orders: 'Order|Orders',
  OrderStatuses: 'Order Status|Order Statuses',
  Categories: 'Category|Categories',
  Offers: 'Offer|Offers',
  OrderCategories: 'Order Category|Order Categories',
  AttributeLogs: 'Attribute Log|Attributes Logs',
  AttributesLogs: 'Attribute Log|Attributes Logs',
  AttachmentTypes: 'Attachment Type|Attachment Types',
  JobPositions: 'Job Position|Job Positions',
  Banks: 'Bank|Banks',
  ServiceTypes: 'Service Type|Service Types'
}
