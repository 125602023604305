<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <btn
    type="submit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </btn>
</template>

<script>
export default {
  name: 'Submit',
  components: { Btn: () => import('./Btn') }
}
</script>
