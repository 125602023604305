/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  name: 'حسابي',
  photo: 'صورة الملف الشخصي',
  accountInformation: 'بيانات الحساب',
  personalInformation: 'البيانات الشخصية',
  security: 'الأمان'
}
