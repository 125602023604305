/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  methods: {
    startProgress () {
      this.$root.RootProgressBar = !0
    },
    stopProgress () {
      this.$root.RootProgressBar = !1
    }
  },
  beforeMount () {
    if (!this.$root.$createInterceptors) {
      this.$axios.interceptors.request.use(config => {
        this.startProgress()
        return config
      }, error => {
        this.stopProgress()
        return Promise.reject(error)
      })
      this.$axios.interceptors.response.use(response => {
        this.stopProgress()
        return response
      }, error => {
        this.stopProgress()
        return Promise.reject(error)
      })
      this.$root.$createInterceptors = true
    }
  }
}
