<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<script>
import NumberInput from './NumberInput'

export default {
  name: 'FloatInput',
  extends: NumberInput,
  props: {
    escapeInput: {
      type: [Array, String],
      default: () => (['.'])
    }
  }
}
</script>
