/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '@app/stub'

const url = StubUrl('Role')
export default {
  ...Stub(url())
}
