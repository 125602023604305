/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  username: 'Login username',
  name: 'Your full name',
  mobile: 'Your mobile number',
  organization_name: 'Organization Name',
  commercial_register: 'Commercial Register number',
  vat_number: 'TAX number in your country',
  bank_id: 'Salary Bank',
  have_obligations: 'Do you have obligations or debts?',
  assign_order_title: 'Assign orders to user'
}
