/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  are_you_sure: 'Are you sure?',
  sure_logout: 'Are you sure the account will be logged out?',
  export_all: 'Are you sure to export all data?',
  confirm_delete: 'Are you sure to delete data?',
  error: 'An error occurred, please try again later.',
  whoops: 'Whoops',
  not_found_message: 'The request not found',
  window_blocked: 'Please make sure to allow pop-ups in your browser to be able to download files. It is recommended to use the Google Chrome browser for all the services and features of the program.',
  404: 'Sorry, the requested page was not found',
  403: 'The requested data cannot be accessed',
  active_user_confirm: 'The account will be activated, do you want to continue?',
  inactive_user_confirm: 'The account will be disabled and the user will not be able to use the account, do you want to continue?',
  max_size: 'Maximum size is {size} MB',
  signature_is_empty: 'Please enter signature',
  signature_saved: 'Signature saved successfully'
}
