/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  title: 'Create new account',
  subtitle: 'Create your account now',
  have_account: 'Have Account ? '
}
