/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  header_title: 'Your real estate solutions',
  header_subtitle: 'Flexible and affordable solutions that enable you to own the home of your dreams',
  header_btn: 'Get Started',
  services: [
    {
      text: 'Financing solutions'
    },
    {
      text: 'Mortgage solutions'
    },
    {
      text: 'consulting services'
    },
    {
      text: 'Consolidation of installments'
    }
  ]
}
