<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      @submitClickForm="submitClickForm"
    >
      <template #form="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container>
            <app-row>
              <app-text-input
                v-model="selectedItem.name_ar"
                cols="12"
                name="name_ar"
                required
                sm="6"
              />
              <app-text-input
                v-model="selectedItem.name_en"
                cols="12"
                name="name_en"
                required
                sm="6"
              />
              <app-number-input
                v-model="selectedItem.order_by"
                cols="12"
                name="order_by"
                sm="6"
              />
            </app-row>
            <app-row>
              <app-switcher
                v-model="selectedItem.active"
                cols="auto"
                name="active"
              />
            </app-row>
          </v-container>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'

export default {
  name: 'AttachmentTypes',
  helperApiName: 'attachmentType',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Utilities.AttachmentType',
      headers: [
        'name',
        {
          text: 'status',
          value: 'active_to_string'
        },
        'order_by',
        'control'
      ]
    }
  },
  computed: {
    defaultSelectedItem () {
      return {
        order_by: 0,
        active: !0
      }
    }
  },
  mounted () {
    this.checkPermission('index')
  }
}
</script>
