/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'
import { StubUrl } from '@app/stub'
import { Tools } from '@helpers/Tools'

const url = StubUrl('Setting')
export default {
  all: () => axios.get(url()),
  save: (data) => {
    const formData = new FormData()
    Tools.appendArray(formData, data)
    return axios.post(url(), formData)
  }
}
