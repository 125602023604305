/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

const primary = '#BE9982'
// const secondary = '#D8C4B2'
// const accent = '#F3E8DC'
const secondary = '#ECE9E4'
const accent = '#F8F8F7'

// const accent2 = '#ECE9E4'
// const accent3 = '#F8F8F7'

export default {
  primary,
  secondary,
  accent,
  gold: '#C7A359',
  orange: '#f7941e',
  blue: '#1C75BC',
  pink: '#EC008C',
  yellow: '#efcc2a',
  'dark-blue': '#00838f',
  'light-blue': '#4cbac3',
  white: '#fafafa',
  footer: secondary,
  toolbar: primary,
  bar: primary
}
