/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  login_subtitle: 'Welcome back',
  forget_password: 'Forget password ?',
  no_account: 'No account? ',
  create_account: 'Create new account',
  resend_code: 'Resend Code',
  login_with: 'Login With {name}',
  resend_after: 'You can resend after: {v}'
}
