/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '/login',
    name: APP_ROUTES.auth.login,
    component: () => import(/* webpackChunkName: "LoginPage" */ '@pages/auth/Login'),
    meta: {
      guest: !0
    }
  }
  // {
  //   path: '/login-otp',
  //   name: APP_ROUTES.auth.otp,
  //   component: () => import(/* webpackChunkName: "LoginOtpPage" */ '@pages/auth/LoginOtp'),
  //   props: !0,
  //   meta: {
  //     guest: !0
  //   }
  // }
  // {
  //   path: '/register',
  //   name: APP_ROUTES.auth.register,
  //   component: () => import(/* webpackChunkName: "RegisterPage" */ '@pages/auth/Register'),
  //   meta: {
  //     guest: !0
  //   }
  // },
  // {
  //   path: '/forgot-password',
  //   name: APP_ROUTES.auth.forgotPassword,
  //   component: () => import(/* webpackChunkName: "ForgotPasswordPage" */ '@pages/auth/ForgotPassword'),
  //   meta: {
  //     guest: !0
  //   }
  // }
]
