<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<script>
import AutoSelect from './AutoSelect'

export default {
  name: 'FilterSelect',
  extends: AutoSelect,
  props: {
    clearable: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {}
  },
  computed: {
    selectItems: {
      get () {
        return [
          { text: this.parseAttribute('yes'), value: 1 },
          { text: this.parseAttribute('no'), value: 0 }
        ]
      },
      set (v) {
        this.$emit('set-items', v)
      }
    }
  }
}
</script>
