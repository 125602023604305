<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app-bar
    :height="60"
    app
    class="d-print-none app-bar"
    color="bar"
    dark
    elevation="8"
    flat
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-btn
      v-if="showDrawerIcon"
      :x-large="AppIsSmall"
      icon
      @click.stop="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-col cols="auto">
      <v-row>
        <v-img
          v-if="!AppIsSmall"
          :src="AppLogo"
          class="pointer"
          contain
          max-height="100%"
          width="50px"
          @click="goToHome"
        />
        <v-col
          :class="`text-body-1 align-self-center pa-0`"
          cols="auto"
        >
          <div
            class="pointer"
            @click="goToHome"
          >
            {{ AppName }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-tabs
      v-if="landingBarTabs.length>0 && !AppIsSmall && !isLogin"
      v-model="tabModel"
    >
      <v-tab
        v-for="(tab,tabIndex) in landingBarTabs"
        :key="`tab-${tabIndex}`"
        v-bind="tab"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-spacer />
    <!--<app-language-menu />-->
    <app-notifications-menu v-if="authUser" />
    <v-btn
      v-if="showToggleTheme"
      icon
      @click="themeDark = !themeDark"
    >
      <v-icon>mdi-palette</v-icon>
    </v-btn>
    <v-btn
      v-if="authUser"
      icon
      @click.stop="logout"
    >
      <v-icon>logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: 'Bar',
  props: {
    navigation: {
      type: Boolean,
      default: () => undefined
    },
    hideDrawerIcon: {
      type: Boolean,
      default: () => !0
    },
    showToggleTheme: {
      type: Boolean,
      default: () => !1
    }
  },
  data: () => ({
    tabModel: null
  }),
  computed: {
    showDrawerIcon () {
      return !this.hideDrawerIcon
      // return this.AppIsSmall
    },
    drawer: {
      get () {
        return this.navigation
      },
      set (v) {
        this.$emit('update:navigation', Boolean(v))
      }
    }
  },
  methods: {
    logout () {
      this.confirmMessage(this.$t('messages.sure_logout'), async () => {
        await this.apiService.auth.logout({ push_token: window.push_token || null }).finally(() => this.logoutUser(true))
      }, () => {
      })
    },
    goToHome () {
      const name = !this.authUser ? this.APP_ROUTES.homePage : this.APP_ROUTES.user.home
      // const name = this.APP_ROUTES.homePage
      this.navigate(name)
    }
  }
}
</script>
<style>
.app-bar .v-toolbar__content {
  /*padding-left: 0 !important;*/
  /*padding-right: 0 !important;*/
}
</style>
