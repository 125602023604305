/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  user_id: 'المستخدم',
  role_id: 'الوظيفة',
  roles_id: 'الوظائف',
  permission_id: 'الصلاحية',
  permissions_id: 'الصلاحيات',
  country_id: 'الدولة',
  countries_id: 'الدول',
  city_id: 'المدينة',
  cities_id: 'المدن',
  order_id: 'الطلب',
  order_no: 'رقم الطلب',
  orders_id: 'الطلبات',
  order_status_id: 'حالة الطلب',
  order_statuses_id: 'حالات الطلب',
  category_id: 'القسم',
  categories_id: 'الأقسام',
  offer_id: 'العرض',
  offers_id: 'العروض',
  order_category_id: 'تصنيف الطلب',
  order_categories_id: 'تصنيفات الطلبات',
  customer_id: 'العميل',
  provider_id: 'مزود الخدمة',
  id: 'ID',
  login_id: 'حقل الدخول',
  username: 'اسم المستخدم',
  name: 'الاسم',
  name_ar: 'الاسم بالعربية',
  name_en: 'الاسم بالانجليزية',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  mobile: 'رقم الجوال',
  email: 'البريد الإلكتروني',
  locale: 'اللغة',
  active: 'فعال',
  code: 'الكود',
  send_time: 'وقت الإرسال',
  token: 'الرمز',
  read_at: 'وقت القراءة',
  key: 'المفتاح',
  order_by: 'ترتيب العرض',
  app_name: 'اسم الموقع',
  app_name_ar: 'اسم البرنامج بالعربية',
  app_name_en: 'اسم البرنامج بالإنجليزية',
  description: 'الوصف',
  description_ar: 'الوصف بالعربية',
  description_en: 'الوصف بالانجليزية',
  job_title: 'المسمى الوظيفي',
  company: 'الشركة',
  company_id: 'الشركة',
  country: 'الدولة',
  city: 'المدينة',
  phone: 'رقم الهاتف',
  whatsapp: 'Whatsapp',
  two_factor_auth: 'التحقق بخطوتين',
  time: 'الوقت',
  from_time: 'الوقت من',
  to_time: 'الوقت إلى',
  date: 'التاريخ',
  from_date: 'التاريخ من',
  to_date: 'التاريخ إلى',
  type: 'النوع',
  status: 'الحالة',
  push_token: 'رمز الجهاز',
  verification_code: 'رمز التحقق',
  attachment: 'المرفق',
  attachments: 'المرفقات',
  notification_email: 'بريد الإشعارات',
  notification_mobile: 'جوال الإشعارات',
  web: 'رابط الموقع',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'بريد خدمة العملاء',
  customer_service_mobile: 'جوال خدمة العملاء',
  customer_service_phone: 'هاتف خدمة العملاء',
  google_map_url: 'رابط جوجل ماب',
  app_store_url: 'رابط التطبيق App Store',
  play_store_url: 'رابط التطبيق Play Store',
  address: 'العنوان',
  address_ar: 'العنوان بالعربية',
  address_en: 'العنوان بالإنجليزية',
  contact_us: 'تواصل معنا',
  contact_us_ar: 'تواصل معنا بالعربية',
  contact_us_en: 'تواصل معنا بالانجليزية',
  about_us: 'من نحن',
  about_us_ar: 'من نحن بالعربية',
  about_us_en: 'من نحن بالانجليزية',
  privacy_policy: 'سياسة الخصوصية',
  privacy_policy_ar: 'سياسة الخصوصية بالعربية',
  privacy_policy_en: 'سياسة الخصوصية بالانجليزية',
  terms_and_conditions: 'الشروط والأحكام',
  terms_and_conditions_ar: 'الشروط والأحكام بالعربية',
  terms_and_conditions_en: 'الشروط والأحكام بالانجليزية',
  commercial_register: 'السجل التجاري',
  commercial_name: 'الاسم التجاري',
  commercial_name_ar: 'الاسم التجاري بالعربية',
  commercial_name_en: 'الاسم التجاري بالإنجليزية',
  vat_number: 'الرقم الضريبي',
  notification_methods: 'طرق الإشعارات',
  avatar: 'الصورة',
  blobAvatar: 'الصورة',
  created_at: 'تاريخ الإضافة',
  from_created_at: 'تاريخ الإضافة من',
  to_created_at: 'تاريخ الإضافة إلى',
  message: 'الرسالة',
  price: 'السعر',
  notes: 'ملاحظات',
  icon: 'الأيقونة',
  subject: 'العنوان',
  image: 'الصورة',
  images: 'الصور',
  choose_image: 'اختر صورة',
  update_image: 'تعديل الصورة',
  url: 'الرابط',
  amount: 'القيمة',
  discount: 'الخصم',
  discount_type: 'نوع الخصم',
  discount_amount: 'قيمة الخصم',
  meta: 'بيانات',
  quantity: 'الكمية',
  location: 'الموقع',
  tax: 'الضريبة',
  new_order_status: 'حالة الطلب الجديد',
  pending_order_status: 'حالة الطلب المعلق',
  accepted_order_status: 'حالة الطلب المقبول',
  completed_order_status: 'حالة الطلب المكتمل',
  canceled_order_status: 'حالة الطلب الملغي',
  fixed_app_fees: 'رسوم التطبيق',
  percent_app_fees: 'نسبة التطبيق',
  payable_id: 'رقم المرجع',
  payable_type: 'نوع المرجع',
  total: 'المجموع',
  data: 'البيانات',
  from_discount_amount: 'قيمة الخصم من',
  to_discount_amount: 'قيمة الخصم إلى',
  expire_date: 'تاريخ الانتهاء',
  from_expire_date: 'تاريخ الانتهاء من',
  to_expire_date: 'تاريخ الانتهاء الى',
  read: 'تم القراءة',
  ref_key: 'الرقم المرجعي',
  attachment_type: 'نوع المرفق',
  percentage: 'النسبة',
  vat: 'الضريبة',
  vat_amount: 'قيمة الضريبة',
  payment_date: 'تاريخ الدفعة',
  from_payment_date: 'تاريخ الدفعة من',
  to_payment_date: 'تاريخ الدفعة إلى',
  payment_type: 'نوع الدفعة',
  paid_amount: 'المبلغ المدفوع',
  color: 'اللون',
  symbol: 'الرمز',
  contact: 'التواصل',
  start_date: 'تاريخ البداية',
  from_start_date: 'تاريخ البداية من',
  to_start_date: 'تاريخ البداية إلى',
  end_date: 'تاريخ النهاية',
  from_end_date: 'تاريخ النهاية من',
  to_end_date: 'تاريخ النهاية إلى',
  start_time: 'وقت البداية',
  from_start_time: 'وقت البداية من',
  to_start_time: 'وقت البداية إلى',
  end_time: 'وقت النهاية',
  from_end_time: 'وقت النهاية من',
  to_end_time: 'وقت النهاية إلى',
  database: 'البرنامج',
  mail: 'البريد الإلكتروني',
  sms: 'الرسائل النصية',
  pay_date: 'تاريخ الدفع',
  from_pay_date: 'تاريخ الدفع من',
  to_pay_date: 'تاريخ الدفع إلى',
  due_amount: 'المبلغ المستحق',
  customer_name: 'اسم العميل',
  signature: 'التوقيع',
  stamp: 'الختم',
  other: 'أخرى',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  other_address: 'عنوان آخر',
  order_amount: 'قيمة الطلب',
  order_total: 'إجمالي الطلب',
  order_details: 'تفاصيل الطلب',
  order_notes: 'ملاحظات الطلب',
  closed_date: 'تاريخ الإغلاق',
  closed_time: 'وقت الإغلاق',
  closed_by_id: 'أغلق بواسطة',
  cancel_reason: 'سبب الإلغاء',
  cancel_date: 'تاريخ الإلغاء',
  cancel_time: 'وقت الإلغاء',
  canceled_by_id: 'ألغي بواسطة',
  attachment_type_id: 'Attachment Type',
  attachment_types_id: 'Attachment Types',
  job_position_id: 'جهة العمل',
  job_positions_id: 'جهات العمل',
  bank_id: 'البنك',
  banks_id: 'البنوك',
  service_type_id: 'الخدمة المقدمة',
  service_types_id: 'الخدمات المقدمة',
  age: 'العمر',
  have_obligations: 'يوجد التزامات',
  salary: 'الراتب',
  owner_information: 'بيانات المالك',
  owner_name: 'اسم المالك',
  owner_mobile: 'جوال المالك',
  down_payment: 'الدفعة الأولى',
  amount_before_variance: 'قيمة العقار قبل التفاوت',
  amount_after_variance: 'قيمة العقار بعد التفاوت',
  finance_amount: 'مبلغ التمويل العقاري',
  owner_check: 'شيك المالك',
  fees_amount: 'قيمة السعي',
  finance_calc_amount: 'حسبة التمويل العقاري',
  real_estate_installment: 'القسط العقاري',
  finance_bank_id: 'بنك التمويل',
  no_user: 'لا يوجد مستخدم',
  follow_up_orders: 'طلبات المتابعة',
  hide_notes: 'إخفاء الملاحظات'
}
