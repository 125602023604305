/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import visitorRoutes from './visitor-routes'
import panelRoutes from './panel-routes'
import authenticationRoutes from './authentication-routes'
import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "HomeLayout" */ '@layouts/LandingLayout'),
    children: [
      {
        path: '',
        // alias: '',
        name: APP_ROUTES.homePage,
        component: () => import(/* webpackChunkName: "MainPage" */ '@pages/HomePage')
      },
      ...visitorRoutes,
      ...authenticationRoutes
    ]
  },
  {
    path: '/panel',
    component: () => import(/* webpackChunkName: "AppLayout" */ '@layouts/AppLayout'),
    children: [
      ...panelRoutes
    ]
  }
]
