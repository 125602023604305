/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import '@/env'
import 'babel-polyfill'
import Vue from '@plugins/vue'
import vuetify from '@plugins/vuetify'
import App from './App'
import router from '@plugins/vue-router'
import i18n from '@plugins/vuetify-vue-i18n'
import store from '@plugins/vuex'
import { ProgressMixin, RootMixin } from '@mixins'
import '@app/bootstrap'

// require('@app/bootstrap')

Vue.config.productionTip = process.env.NODE_ENV === 'development'
Vue.config.devtools = process.env.NODE_ENV === 'development'

const app = new Vue({
  el: '#app',
  vuetify,
  router,
  i18n,
  store,
  mixins: [ProgressMixin, RootMixin],
  render: h => h(App)
})

export default app
