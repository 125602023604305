/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import * as Config from '@config'

const defaultCurrency = { ...Config.currency.value }
const CURRENCY_STORAGE_KEY = Config.currency.storageKey

export default {
  methods: {
    SetAppCurrency (currency, reload = !1) {
      this.AppCurrency = currency
      reload === !0 && setTimeout(() => this.$nextTick(() => window.location.reload()), 100)
    },
    IniAppCurrency () {
      // localStorage.removeItem(CURRENCY_STORAGE_KEY)
      this.SetAppCurrency(localStorage.getItem(CURRENCY_STORAGE_KEY), !1)
    }
  },
  computed: {
    AppCurrency: {
      get () {
        return this.$root.RootCurrency
      },
      set (currency) {
        currency = currency || { ...defaultCurrency }
        if (typeof currency === 'string') currency = JSON.parse(currency)
        currency.balance = parseFloat(currency.balance) || 1.0
        const { balance, code, short_name } = currency
        localStorage.setItem(CURRENCY_STORAGE_KEY, JSON.stringify({
          code,
          short_name,
          balance
        }))
        // axios
        this.$axios.defaults.headers.common['App-Currency'] = code
        this.$axios.defaults.headers.common['App-Currency-Balance'] = balance
        this.$root.RootCurrency = currency
      }
    }
  }
}
