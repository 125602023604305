/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { camelCase, upperFirst } from 'lodash'
import Vue from 'vue'

const requireComponent = require.context('@components/base', true, /\.vue$/)

for (let file of requireComponent.keys()) {
  file = file.replace('./', '')

  const name = file.split('/').pop().replace(/\.\w+$/, '').replace(/-+/, '')

  const componentName = `App${upperFirst(camelCase(name))}`
  Vue.component(componentName, () => import(`@components/base/${file}`))
}
