/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { kebabCase } from 'lodash'
import { APP_ROUTES } from '@/routes/list'

const files = require.context('@pages/bootstrap', true, /\.vue$/)
const routes = [
  {
    name: APP_ROUTES.user.home,
    path: 'dashboard',
    component: () => import(/* webpackChunkName: "HomeUserPage" */ '@pages/bootstrap/user/Home'),
    meta: {
      auth: !0
    }
  },
  {
    name: APP_ROUTES.order.index,
    path: '/panel/sales/order/index',
    component: () => import(/* webpackChunkName: "OrderIndexPage" */ '@pages/sales/order/Index'),
    props: !0,
    meta: {
      auth: !0
    },
    children: [
      {
        name: APP_ROUTES.order.show,
        path: '/panel/sales/order/:id/show',
        props: !0,
        components: {
          show: () => import(/* webpackChunkName: "ShowOrderPage" */ '@pages/sales/order/OrderPage')
        }
      }
    ]
  }
]
// console.log(routes)
for (let file of files.keys()) {
  file = file.replace('./', '')
  const arr = file.split('/')
  // console.log(arr)
  for (const s in arr) {
    const n = arr[s].replace(/\.\w+$/, '')
    arr[s] = kebabCase(n)
    // arr[s] = camelCase(n)
  }

  const name = 'panel.' + arr.join('.').replace('.id.', '.')
  // const path = arr.join('/').toString().replace('panel/', '').replace('/id/', '/:id/')
  const path = arr.join('/').toString()
  // console.log(path, name)
  const props = path.indexOf('/:id/') !== -1
  if (!routes.find(e => e.name === name)) {
    // continue
    routes.push({
      path,
      name,
      props,
      component: () => import(`@pages/bootstrap/${file}`),
      meta: {
        auth: !0
      }
    })
  }
}
// console.log(routes)
export default routes

// export default [
//   {
//     path: 'dashboard',
//     name: 'homeUser',
//     alias: '',
//     component: () => import(/* webpackChunkName: "HomeUserPage" */ '@pages/HomeUser'),
//   },
//   {
//     path: 'settings',
//     name: 'settings',
//     component: () => import(/* webpackChunkName: "settingPage" */ '@pages/Setting'),
//   },
//   {
//     path: 'profile',
//     name: 'profile',
//     component: () => import(/* webpackChunkName: "ProfilePage" */ '@components/user/Profile'),
//   },
//   {
//     path: 'role',
//     name: 'role',
//     component: () => import(/* webpackChunkName: "rolePage" */ '@pages/Roles'),
//   },
//   {
//     path: 'user',
//     name: 'user',
//     component: () => import(/* webpackChunkName: "userPage" */ '@pages/Users'),
//   },
// ]
