<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <slide-fade-transition>
    <v-container
      v-if="form && authUser"
      fluid
    >
      <app-row justify="center">
        <v-col
          order="2"
          order-sm="1"
          v-bind="formCols"
        >
          <app-card
            :title="parseAttribute('profilePage.name')"
            icon="person"
          >
            <v-container>
              <v-tabs
                v-model="tab"
                :right="!AppRtl"
                :show-arrows="AppIsSmall"
                :vertical="!AppIsSmall"
              >
                <v-tab>
                  {{ $t('profilePage.accountInformation') }}
                </v-tab>
                <!--<v-tab>-->
                <!--  {{ $t('profilePage.personalInformation') }}-->
                <!--</v-tab>-->
                <v-tab>
                  {{ $t('profilePage.security') }}
                </v-tab>

                <v-tab-item>
                  <app-form
                    ref="formAccount"
                    v-slot="v"
                    :errors="accountErrors"
                  >
                    <v-container>
                      <account-form
                        v-model="form"
                        :profile="!userHasPermission('account')"
                      >
                        <!--<template #before>
                        <v-row
                          v-if="form.signature"
                          class="mb-3"
                          dense
                          justify="center"
                          justify-sm="start"
                        >
                          <v-col cols="12">
                            <h4>{{ parseAttribute('signature') }}</h4>
                          </v-col>
                          <v-col
                            cols="auto"
                          >
                            <v-card color="grey">
                              <v-img
                                :lazy-src="AppLogo"
                                width="200"
                                :src="form.signature"
                                max-height="100%"
                              />
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>-->
                        <!--<template #default>
                        <signature-form
                          v-if="!form.signature"
                          ref="signature"
                          :errors="errors"
                          :loading="loading"
                          @signature="updateSignature"
                        />
                      </template>-->
                        <template #after>
                          <v-col
                            v-if="userHasPermission('account')"
                            cols="12"
                          >
                            <v-row>
                              <v-col all="auto">
                                <app-btn
                                  :disabled="v.invalid"
                                  :loading="loading"
                                  @click="submitAccount"
                                >
                                  {{ $t('update') }}
                                </app-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </account-form>
                    </v-container>
                  </app-form>
                </v-tab-item>
                <!--                <v-tab-item>
                  <app-form
                    ref="formProfile"
                    v-slot="v"
                    :errors="profileErrors"
                  >
                    <v-container>
                      <personal-form
                        v-model="form"
                        :profile="!userHasPermission('profile')"
                      >
                        <template #after>
                          <v-row>
                            <v-col
                              v-if="userHasPermission('profile')"
                              cols="12"
                            >
                              <app-btn
                                :disabled="v.invalid"
                                :loading="loading"
                                @click="submitProfile"
                              >
                                {{ $t('update') }}
                              </app-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </personal-form>
                    </v-container>
                  </app-form>
                </v-tab-item>-->
                <v-tab-item>
                  <app-form
                    ref="formSecurity"
                    v-slot="v"
                    :errors="securityErrors"
                  >
                    <v-container fluid>
                      <password-form
                        v-model="form"
                      />
                      <v-row>
                        <app-col all="auto">
                          <app-btn
                            :disabled="v.invalid || (!form.password && !form.password_confirmation)"
                            :loading="loading"
                            @click="submitSecurity"
                          >
                            {{ $t('save') }}
                          </app-btn>
                        </app-col>
                      </v-row>
                    </v-container>
                  </app-form>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </app-card>
        </v-col>
        <!--      <v-col
          order="1"
          order-sm="2"
          v-bind="avatarCols"
        >
          <app-card
            :title="$t('profilePage.photo')"
            class="text-center"
          >
            <v-container>
              <v-row
                dense
                justify="end"
              >
                <v-col cols="auto">
                  <v-btn
                    v-if="authUser.avatar"
                    :loading="loading"
                    color="error"
                    icon
                    x-large
                    @click="removeLogo"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <app-avatar-input
              v-model="form.avatar"
              ref="avatar"
              :label="authUser.name"
              :url="avatarUrl"
              file-name="blobAvatar"
              @saved="saved"
            />
          </app-card>
        </v-col>-->
      </app-row>
    </v-container>
  </slide-fade-transition>
</template>

<script>
import { CountryMixin, MetaInfoMixin } from '@mixins'
import AccountForm from '@components/user/AccountForm'
import PasswordForm from '@components/user/PasswordForm'
import SlideFadeTransition from '@components/SlideFadeTransition'
import PersonalForm from '@components/user/PersonalForm'

export default {
  name: 'Profile',
  components: { PersonalForm, SlideFadeTransition, PasswordForm, AccountForm },
  mixins: [MetaInfoMixin, CountryMixin],
  data () {
    return {
      modelPermission: 'Profile',
      avatarCols: {
        cols: 12,
        sm: 4,
        md: 3,
        lg: 3,
        xl: 2
      },
      formCols: {
        cols: 12,
        sm: 8,
        md: 8,
        lg: 9,
        xl: 9
      },
      loading: !1,
      tab: 0,
      form: null,
      accountErrors: {},
      profileErrors: {},
      securityErrors: {}
    }
  },
  computed: {
    avatarUrl () {
      return this.apiService.user.updateAvatar(!0)
    }
  },
  watch: {
    authUser: {
      deep: !0,
      immediate: !0,
      handler (v) {
        this.form = v ? { ...v } : v
      }
    },
    tab (v) {
      this.form = { ...this.authUser }
      this.accountErrors = {}
      this.profileErrors = {}
      this.securityErrors = {}
      for (const f in this.$refs) {
        if (f.slice(0, 4) === 'form' && this.$refs[f].reset) {
          this.$refs[f].reset()
        }
      }
    }
  },
  mounted () {
    this.form = { ...this.authUser }
  },
  methods: {
    saved ({ data }) {
      if (data?.success === !0) {
        this.$root.updateProfile(data.data)
        this.$refs.avatar.$emit('dialog', false)
      }
    },
    async submitAccount () {
      if (this.loading) return
      this.loading = !0
      this.accountErrors = {}
      try {
        const { _message, _data } = await this.apiService.user.updateAccount(this.form)
        _message && this.alertSuccess(_message)
        _data && (this.authUser = _data)
      } catch (e) {
        const { _errors, _message } = e
        this.accountErrors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    },
    async submitProfile () {
      if (this.loading) return
      this.loading = !0
      this.profileErrors = {}
      try {
        const { _message, _data } = await this.apiService.user.updateProfile(this.form)
        _message && this.alertSuccess(_message)
        _data && (this.authUser = _data)
      } catch (e) {
        const { _errors, _message } = e
        this.profileErrors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    },
    async submitSecurity () {
      if (this.loading) return
      this.loading = !0
      this.securityErrors = {}
      try {
        const { password, password_confirmation } = this.form
        const { _message, _success, _data } = await this.apiService.user.updatePassword({ password, password_confirmation })
        if (_success) {
          _message && this.alertSuccess(_message)
          _data && (this.authUser = _data)
        }
        try {
          this.$refs.formSecurity?.reset()
        } catch (e) {
          const { _message } = e || {}
          _message && this.alertError(_message)
        }
      } catch (e) {
        const { _errors, _message } = e
        this.securityErrors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    },
    updateSignature (v) {
      if (this.loading || !v) return
      this.loading = !0
      this.apiService.user.updateSignature(v).then(({ _data, _message }) => {
        _message && this.alertSuccess(_message)
        if (_data) {
          this.authUser = _data
          this.$nextTick(() => {
            this.$refs.signature && this.$refs.signature.clearSignature()
          })
        }
      }).catch(({ _message, ...e }) => {
        this.alertError(_message || e?.message)
      }).finally(() => (this.loading = !1))
    }
  }
}
</script>
