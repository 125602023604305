<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<!--<template>-->
<!--  <base-btn-->
<!--    v-bind="$attrs"-->
<!--    v-on="$listeners"-->
<!--  >-->
<!--    <slot />-->
<!--  </base-btn>-->
<!--  &lt;!&ndash;<app-tooltip&ndash;&gt;-->
<!--  &lt;!&ndash;  v-if="!tooltipDisabled"&ndash;&gt;-->
<!--  &lt;!&ndash;  :disabled="tooltipDisabled"&ndash;&gt;-->
<!--  &lt;!&ndash;  :text="getTooltip"&ndash;&gt;-->
<!--  &lt;!&ndash;  :tooltip-bottom="tooltipBottom"&ndash;&gt;-->
<!--  &lt;!&ndash;  v-bind="$attrs"&ndash;&gt;-->
<!--  &lt;!&ndash;  v-on="$listeners"&ndash;&gt;-->
<!--  &lt;!&ndash;&gt;&ndash;&gt;-->
<!--  &lt;!&ndash;  <base-btn&ndash;&gt;-->
<!--  &lt;!&ndash;    v-bind="$attrs"&ndash;&gt;-->
<!--  &lt;!&ndash;    v-on="$listeners"&ndash;&gt;-->
<!--  &lt;!&ndash;  >&ndash;&gt;-->
<!--  &lt;!&ndash;    <slot />&ndash;&gt;-->
<!--  &lt;!&ndash;  </base-btn>&ndash;&gt;-->
<!--  &lt;!&ndash;</app-tooltip>&ndash;&gt;-->
<!--  &lt;!&ndash;<base-btn&ndash;&gt;-->
<!--  &lt;!&ndash;  v-else&ndash;&gt;-->
<!--  &lt;!&ndash;  v-bind="$attrs"&ndash;&gt;-->
<!--  &lt;!&ndash;  v-on="$listeners"&ndash;&gt;-->
<!--  &lt;!&ndash;&gt;&ndash;&gt;-->
<!--  &lt;!&ndash;  <slot />&ndash;&gt;-->
<!--  &lt;!&ndash;</base-btn>&ndash;&gt;-->
<!--</template>-->

<script>
import BaseBtn from '@components/base/btn/BaseBtn'

export default {
  name: 'Btn',
  // components: {
  // BaseBtn: () => import('./BaseBtn')
  // },
  extends: BaseBtn,
  props: {
    tooltip: {
      type: String,
      default: () => undefined
    },
    tooltipBottom: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getTooltip () {
      if (this.tooltip) return this.parseAttribute(this.tooltip)
      return null
    },
    tooltipDisabled () {
      return this.getTooltip === null || this.getTooltip === undefined || this.AppIsSmall
    }
  },
  mounted () {
    // console.log(this.$attrs)
  }
}
</script>
