/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import moment from 'moment-hijri'
import Vue from 'vue'
// import config from '@config'

Vue.prototype.$moment = moment
window.moment = moment
// moment.locale(config.locale.defaultLocale)
// moment.locale('en')

export default moment
