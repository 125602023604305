/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  header_title: 'مأواكم العقارية',
  header_subtitle: 'نقدم لك أفضل الحلول التمويلية بتسهيلات و أقساط ميسرة',
  header_btn: 'احصل على تمويلك الآن',
  services: [
    {
      text: 'حلول تمويلية'
    },
    {
      text: 'الرهن العقاري'
    },
    {
      text: 'خدمات استشارية'
    },
    {
      text: 'توحيد الأقساط'
    }
  ]
}
