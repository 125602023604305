<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <app-top-input-label
          v-if="getLabel && !hideTopLabel"
          :label="getTopLabel"
        />
        <v-col>
          <slot name="top" />
          <v-text-field
            ref="string"
            v-model="val"
            v-numeric="vNumericEscapes"
            :autocomplete="getAutocomplete"
            :clearable="getClearable"
            :counter="counter"
            :counter-value="a => a ? a.length : 0"
            :disabled="disabled"
            :error-messages="errorMessages"
            :hide-details="hideDetails"
            :label="getLabel"
            :placeholder="getPlaceholder"
            :readonly="readonly"
            type="tel"
            v-bind="$attrs"
            @input="input"
            v-on="inputListeners"
            @click:clear.stop="clearValue"
          >
            <template
              v-for="(v,slot) in $scopedSlots"
              #[slot]
            >
              <slot
                :name="slot"
              />
            </template>
          </v-text-field>
          <slot />
          <slot name="bottom" />
        </v-col>
      </v-row>
    </v-container>
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :immediate="immediate"
      :name="name"
      :rules="getRules"
      :vid="vid"
      mode="aggressive"
    >
      <v-text-field
        v-show="!1"
        ref="number"
        v-model="inputValue"
        :autocomplete="`${getAutocomplete}-inp`"
        v-bind="$attrs"
      />
      {{ (providerMessages = v.errors) && '' }}
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import { InputsMixin } from '@mixins'

export default {
  name: 'NumberInput',
  mixins: [InputsMixin],
  props: {
    minus: {
      type: Boolean,
      default: () => !1
    },
    autocomplete: {
      type: String,
      default: () => 'off'
    },
    escapeInput: {
      type: [Array, String, Object],
      default: () => ([])
    },
    immediate: {
      type: Boolean,
      default: () => false
    },
    format: {
      type: Boolean,
      default: () => false
    },
    clearInput: {
      type: Function,
      default: () => undefined
    }
  },
  data () {
    return {
      inputValue: null,
      string: null,
      providerMessages: []
    }
  },
  computed: {
    errorMessages () {
      return [...this.getErrorMessages, ...this.providerMessages]
    },
    inputListeners () {
      const vm = this
      return Object.assign({}, this.$listeners,
        {
          input: val => vm.input(val),
          focus: val => vm.focus(val),
          change: val => vm.change(val),
          blur: val => vm.blur(val),
          keypress: val => vm.blur(val),
          keydown: val => vm.blur(val)
        })
    },
    val: {
      set (n) {
        this.setVal(n)
      },
      get () {
        return this.string
      }
    },
    vNumericEscapes () {
      const e = this.escapeInput

      if (this.minus && this.$helpers.isOnlyArray(e) && e.indexOf('-') < 0) {
        e.push('-')
      }

      if (this.format && this.$helpers.isOnlyArray(e) && e.indexOf('.') < 0) {
        e.push('.')
      }

      return e
    }
  },
  watch: {
    value (n) {
      this.val = n
    }
  },
  mounted () {
    this.iniComponent()
  },
  methods: {
    iniComponent () {
      // this.val = this.value || null;
      this.val = this.value
    },
    toNumber (v) {
      return this.$helpers.toNumber(v)
    },
    toNumberFormat (v) {
      if (!v) return v
      return this.format === true ? this.$helpers.toNumberFormat(this.$helpers.toNumber(v)) : '' + v
    },
    input (value) {
      value = value === null || value === undefined ? 0 : this.toNumber(value)
      this.$refs.number.$emit('input', value)
      this.$emit('input', value)
    },
    focus ($event) {
      this.$nextTick(function () {
        this.$refs.number.$emit('focus', $event)
        this.$emit('focus', $event)
      })
    },
    change ($event) {
      this.$nextTick(function () {
        this.$refs.number.$emit('change', $event)
        this.$emit('change', $event)
      })
    },
    blur ($event) {
      this.$nextTick(function () {
        this.$refs.number.$emit('blur', $event)
        this.$emit('blur', $event)
      })
    },
    keypress ($event) {
      this.$refs.number.$emit('keypress', $event)
      this.$emit('keypress', $event)
    },
    keydown ($event) {
      this.$refs.number.$emit('keypress', $event)
      this.$emit('keydown', $event)
    },
    setVal (value) {
      this.string = this.toNumberFormat(value)
      this.inputValue = this.toNumber(value)
    },
    clearValue () {
      if (this.clearInput) {
        this.clearInput()
      } else {
        this.input(0)
      }
      // console.log(this.val)
    }
  }
}
</script>
