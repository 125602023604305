<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <component
    :is="getComponentName"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ColSpan',
  props: {
    withCol: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {}
  },
  computed: {
    getComponentName () {
      return this.withCol ? 'app-col' : 'span'
    }
  },
  mounted () {
  }
}
</script>
