<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-list
    :dense="!AppIsSmall"
    class="pa-0"
    tile
  >
    <v-list-item
      @click="themeDark = !themeDark"
    >
      <v-list-item-icon v-if="icon">
        <v-icon>{{ `${themeModeReverse}_mode` }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        :class="{'text-center': center}"
        v-text="$t(`${themeModeReverse}Theme`)"
      />
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'ThemeList',
  props: {
    icon: {
      type: Boolean,
      default: () => !1
    },
    center: {
      type: Boolean,
      default: () => !1
    }
  }
}
</script>
