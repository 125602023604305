<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-autocomplete
              v-model="model"
              :clearable="getClearable"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :label="getLabel"
              :loading="isLoading"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :search-input.sync="search"
              eager
              hide-no-data
              hide-selected
              prepend-icon="mdi-database-search"
              v-bind="$attrs"
              v-on="$listeners"
            />
            <!--<v-list max-height="200">-->
            <!--  <v-list-item>-->
            <!--    asd-->
            <!--  </v-list-item>-->
            <!--  <v-list-item-->
            <!--    v-for="e in entries"-->
            <!--    :key="e.id"-->
            <!--  >-->
            <!--    <v-list-item-title>{{ e.text }}</v-list-item-title>-->
            <!--  </v-list-item>-->
            <!--</v-list>-->
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import { InputsMixin } from '@mixins'
import _ from 'lodash'

export default {
  name: 'SearchInput',
  mixins: [InputsMixin],
  props: {
    url: {
      type: Function,
      default: () => undefined,
      required: !0
    }
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: !1,
    model: null,
    menu: !0,
    search: null
  }),
  watch: {
    model (v) {
      // console.log('Model ', v, 'items ', this.entries)
    },
    search: {
      deep: !0,
      handler: _.debounce(function (...args) {
        this.onSearch(...args)
      }, 500)
    }
  },
  computed: {
    items () {
      return this.entries.map(entry => {
        const Description = entry.Description && entry.Description.length > this.descriptionLimit
          ? entry.Description.slice(0, this.descriptionLimit) + '...'
          : entry.Description
        return Object.assign({}, entry, { Description })
      })
    }
  },
  mounted () {
    // console.log(this.apiService.customer.staticUtilities(!0))
  },
  methods: {
    input (e) {
      this.$emit('input', e || null)
    },
    onSearch (search) {
      // Items have already been requested
      if (this.isLoading || !search || (search && search.length < 3)) return
      this.entries = []
      // console.log('search: ', search, this.model)

      // return
      this.isLoading = !0
      const api = this.url({ search })

      api.then(({ _data }) => {
        this.$nextTick(() => {
          this.entries = _data || []
          // this.search = null
        })
        // console.log(_data)
      }).catch(e => e).finally(() => (this.isLoading = !1))
      // console.log(api)

      return
      // Lazily load input items
      fetch('https://api.publicapis.org/entries').then(res => res.json()).then(res => {
        const { count, entries } = res
        this.count = count
        this.entries = entries
      }).catch(err => {
        console.log(err)
      }).finally(() => (this.isLoading = false))
    },
    checkValue (v) {
      console.log(v)
      return [v.mobile, v.text]
    }
  }
}
</script>
