<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card :title="$t('settingsPage.name')">
    <v-container
      v-if="loading"
      class="fill-height"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader
            type="article,paragraph,actions"
          />
        </v-col>
      </v-row>
    </v-container>
    <app-form
      v-else
      ref="form"
      v-slot="v"
      :errors="errors"
      :submit="submit"
    >
      <v-container
        class="mb-10"
      >
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab">
              <v-tab>{{ $t('settingsPage.general') }}</v-tab>
              <v-tab>{{ $t('settingsPage.orders') }}</v-tab>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <app-text-input
                      v-model="form.app_name_ar"
                      clearable
                      name="app_name_ar"
                      sm="6"
                    />
                    <app-text-input
                      v-model="form.app_name_en"
                      clearable
                      name="app_name_en"
                      sm="6"
                    />
                    <!--<v-row>
                    <app-text-input
                      v-model="form.commercial_name_ar"
                      clearable
                      sm="6"
                      name="commercial_name_ar"
                    />
                    <app-text-input
                      v-model="form.commercial_name_en"
                      clearable
                      sm="6"
                      name="commercial_name_en"
                    />
                    <app-number-input
                      v-model="form.commercial_register"
                      clearable
                      sm="6"
                      name="commercial_register"
                    />
                    <app-number-input
                      v-model="form.vat_number"
                      clearable
                      sm="6"
                      name="vat_number"
                    />
                    <app-float-input
                      v-model="form.tax"
                      clearable
                      sm="6"
                      name="tax"
                      prefix="%"
                    />
                  </v-row>-->
                    <!--
                  <v-row>
                    <app-textarea-input
                      v-model="form.address_ar"
                      clearable
                      cols="12"
                      name="address_ar"
                    />
                    <app-textarea-input
                      v-model="form.address_en"
                      clearable
                      cols="12"
                      name="address_en"
                    />
                  </v-row>-->
                  </v-row>
                  <v-row>
                    <app-textarea-input
                      v-model="form.description_ar"
                      cols="12"
                      name="description_ar"
                      rows="5"
                    />
                    <app-textarea-input
                      v-model="form.description_en"
                      cols="12"
                      name="description_en"
                      rows="5"
                    />
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <!--<v-row>-->
                  <!--  <app-float-input-->
                  <!--    v-model="form.fixed_app_fees"-->
                  <!--    :suffix="$t('sar')"-->
                  <!--    md="6"-->
                  <!--    name="fixed_app_fees"-->
                  <!--  />-->
                  <!--  <app-float-input-->
                  <!--    v-model="form.percent_app_fees"-->
                  <!--    md="6"-->
                  <!--    name="percent_app_fees"-->
                  <!--    suffix="%"-->
                  <!--  />-->
                  <!--</v-row>-->
                  <v-row>
                    <app-number-input
                      v-model="form.follow_up_orders"
                      :suffix="parseAttribute('days')"
                      name="follow_up_orders"
                      required
                      sm="6"
                    />
                    <app-order-statuses
                      class="d-none"
                      name="new_order_status"
                      @set-items="order_statuses = $event"
                    />
                    <app-auto-select
                      v-for="(s,i) in orderStatusSelect"
                      :key="`select-${i}`"
                      v-model="form[s]"
                      :items="order_statuses"
                      :name="s"
                      cols="12"
                      required
                      sm="6"
                    />
                  </v-row>
                </v-container>
              </v-tab-item>
              <!--<v-tab-item>
                <v-container
                  class="fill-height"
                  fluid
                >
                  <v-row>
                    <app-auto-select
                      v-model="form.notification_methods"
                      :items="notification_methods"
                      cols="12"
                      sm="6"
                      multiple
                      name="notification_methods"
                      required
                    />
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span class="text-body-1">{{ parseAttribute('notification_email') }}</span>
                      <v-btn
                        color="primary"
                        icon
                        @click="addNotificationEmail"
                      >
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <template
                      v-for="(m,i) in notification_email"
                    >
                      <app-text-input
                        v-model="notification_email[i]"
                        :key="i.toString()"
                        append-outer-icon="clear"
                        lg="3"
                        md="5"
                        name="notification_email"
                        required
                        @click:append-outer="removeNotificationEmail(i)"
                      />
                    </template>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span class="text-body-1">{{ parseAttribute('notification_mobile') }}</span>
                      <v-btn
                        color="primary"
                        icon
                        @click="addNotificationMobile"
                      >
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <template
                      v-for="(m,i) in notification_mobile"
                    >
                      <app-number-input
                        v-model="notification_mobile[i]"
                        :key="i.toString()"
                        append-outer-icon="clear"
                        lg="3"
                        md="5"
                        name="notification_mobile"
                        required
                        @click:append-outer="removeNotificationMobile(i)"
                      />
                    </template>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <app-text-input
                      v-model="form.customer_service_email"
                      clearable
                      sm="6"
                      name="customer_service_email"
                    />
                    <app-number-input
                      v-model="form.customer_service_mobile"
                      clearable
                      sm="6"
                      name="customer_service_mobile"
                    />
                    <app-number-input
                      v-model="form.customer_service_phone"
                      clearable
                      sm="6"
                      name="customer_service_phone"
                    />
                  </v-row>
                  <v-row>
                    <app-text-input
                      v-model="form.web"
                      clearable
                      sm="6"
                      name="web"
                    />
                    <app-text-input
                      v-model="form.whatsapp"
                      clearable
                      sm="6"
                      name="whatsapp"
                    />
                    <app-text-input
                      v-model="form.twitter"
                      clearable
                      sm="6"
                      name="twitter"
                    />
                    <app-text-input
                      v-model="form.instagram"
                      clearable
                      sm="6"
                      name="instagram"
                    />
                    <app-text-input
                      v-model="form.snapchat"
                      clearable
                      sm="6"
                      name="snapchat"
                    />
                    <app-text-input
                      v-model="form.facebook"
                      clearable
                      sm="6"
                      name="facebook"
                    />
                    <app-text-input
                      v-model="form.linkedin"
                      clearable
                      sm="6"
                      name="linkedin"
                    />
                    <app-text-input
                      v-model="form.google_map_url"
                      clearable
                      sm="6"
                      name="google_map_url"
                    />
                    <app-text-input
                      v-model="form.app_store_url"
                      clearable
                      sm="6"
                      name="app_store_url"
                    />
                    <app-text-input
                      v-model="form.play_store_url"
                      clearable
                      sm="6"
                      name="play_store_url"
                    />
                  </v-row>
                </v-container>
              </v-tab-item>-->
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <app-btn
              :disabled="v.invalid"
              :loading="saving"
              @click="submit"
            >
              {{ $t('save') }}
            </app-btn>
          </v-col>
        </v-row>
      </v-container>
    </app-form>
  </app-card>
</template>

<script>

import { AvatarInputMixin, GetHeadersMixin } from '@mixins'

export default {
  name: 'Settings',
  mixins: [GetHeadersMixin, AvatarInputMixin],
  data () {
    return {
      order_statuses: [],
      loading: !1,
      saving: !1,
      tab: 0,
      errors: {},
      form: {},
      notification_email: [],
      notification_mobile: [],
      notification_methods: this.parseHeaders(['database', 'mail', 'sms', 'push_token']),
      orderStatusSelect: [
        'new_order_status',
        'pending_order_status',
        'accepted_order_status',
        'completed_order_status',
        'canceled_order_status'
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.loading) return
      this.loading = !0
      this.fetchAllAppSettings().then(({ _data }) => this.mountSetting(_data)).catch(r => r).finally(() => (this.loading = !1))
    },
    async saveData (form) {
      if (this.saving) return
      try {
        this.saving = !0
        const { _data, _message } = await this.apiService.setting.save(form)
        _message && this.alertSuccess(_message)
        this.mountSetting(_data)
      } catch (e) {
        const { _errors, _message } = e
        this.errors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.saving = !1
      }
    },
    submit () {
      const form = this.form
      form.notification_email = this.notification_email
      form.notification_mobile = this.notification_mobile
      this.saveData(form)
    },
    mountSetting (data) {
      this.form = data || {}
      this.notification_email = this.form.notification_email || []
      this.notification_mobile = this.form.notification_mobile || []
    },
    addNotificationMobile () {
      this.notification_mobile = [
        ...this.notification_mobile,
        ''
      ]
    },
    removeNotificationMobile (index) {
      this.confirmMessage(() => (this.notification_mobile = this.notification_mobile.filter((v, i) => i !== index)))
    },
    addNotificationEmail () {
      this.notification_email = [
        ...this.notification_email,
        ''
      ]
    },
    removeNotificationEmail (index) {
      this.confirmMessage(() => (this.notification_email = this.notification_email.filter((v, i) => i !== index)))
    }
  }
}
</script>
