<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<script>
import { VDialog } from 'vuetify/lib/components'
import { hideHtmlOverflow, showHtmlOverflow } from '@helpers/Tools'

export default {
  name: 'DialogModal',
  extends: VDialog,
  props: {
    transition: {
      type: String,
      default: () => 'dialog-bottom-transition'
    },
    maxWidth: {
      type: [Number, String],
      default () {
        return this.$vuetify.breakpoint.thresholds.sm
      }
    }
  },
  watch: {
    value (v) {
      // alert(v)
      if (v === !0) {
        hideHtmlOverflow()
      }
      if (v === !1) {
        showHtmlOverflow()
      }
    }
  }
}
</script>
