<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-text-input
    ref="input"
    :clearable="clearable"
    :counter="!1"
    :force-clearable="forceClearable"
    :name="name"
    :prepend-inner-icon="prependIcon"
    :rules="getRules"
    :value="value"
    :vid="vid"
    readonly
    v-bind="$attrs"
    v-on="$listeners"
    @click.stop="click"
    @click:prepend-inner="click"
  >
    <v-dialog
      ref="dialog"
      v-model="modal"
      :max-width="maxWidth"
      :return-value.sync="date"
      :width="width"
      persistent
      v-bind="$attrs"
    >
      <v-time-picker
        v-model="date"
        :max="max"
        :min="min"
        ampm-in-title
        scrollable
        v-bind="$attrs"
      >
        <app-btn
          color="primary"
          text
          @click="save(date)"
        >
          {{ $t('done') }}
        </app-btn>
        <v-spacer />
        <app-btn
          color="error"
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </app-btn>
      </v-time-picker>
    </v-dialog>
  </app-text-input>
</template>

<script>

import { InputsMixin } from '@mixins'
import moment from 'moment'

export default {
  name: 'TimePicker',
  mixins: [InputsMixin],
  props: {
    forceClearable: {
      type: Boolean,
      default: () => !1
    },
    maxWidth: {
      type: [String, Number],
      default: () => 290
    },
    width: {
      type: [String, Number],
      default: () => 290
    },
    prependIcon: {
      type: String,
      default: () => 'access_time'
    },
    clearable: {
      type: Boolean,
      default: () => !1
    },
    min: {
      default: () => undefined
    },
    max: {
      default: () => undefined
    }
  },
  data () {
    return {
      modal: !1,
      date: undefined
    }
  },
  mounted () {
    // this.setTimeValue(this.value)
  },
  methods: {
    setTimeValue (value) {
      if (!value) return
      try {
        const formatted = moment(value).format('HH:mm')
        if (formatted) {
          const v = this.$helpers.fromArabicNumber(formatted)
          this.date = v
          this.emit(v)
        }
      } catch (e) {
        this.date = value
      }
    },
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
    save (v) {
      this.$refs.dialog.save(v)
      this.emit(v)
      this.$nextTick(() => {
        this.focus()
        this.blur()
      })
    },
    click () {
      this.modal = !this.modal
      this.date = this.value || undefined
      if (this.modal === !1) {
        this.$nextTick(() => this.blur())
      }
    },
    cancel () {
      this.modal = !1
      this.$nextTick(() => this.blur())
    },
    emit (v) {
      this.$emit('input', v)
    }
  }
}
</script>
