/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  username: 'اسم مستخدم الدخول',
  name: 'اسمك الكامل',
  mobile: 'رقم الجوال الخاص بك',
  organization_name: 'إسم الشركة أو الاسم التجاري',
  commercial_register: 'الرقم التجاري للمنشأة/الشركة/فرد',
  vat_number: 'الرقم الضريبي في دولتك',
  bank_id: 'بنك إيداع الراتب',
  have_obligations: 'هل يوجد عليك التزامات او ديون؟',
  assign_order_title: 'تحويل الطلبات الى المستخدم'
}
