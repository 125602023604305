/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  login_subtitle: 'مرحباً بك',
  forget_password: 'نسيت كلمة المرور؟',
  no_account: 'ليس لديك حساب؟ ',
  create_account: 'أنشئ حسابك الآن!',
  resend_code: 'إعادة الإرسال',
  login_with: 'تسجيل الدخول باستخدام {name}',
  resend_after: 'يمكنك إعادة الإرسال بعد: {v}'
}
