<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-card
    class="pb-5"
    flat
    tile
  >
    <v-card-title class="pa-0">
      <v-toolbar
        color="primary"
        height="50"
      >
        <v-toolbar-title>{{ notificationItem.subject }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-subtitle />
    <v-card-subtitle>
      {{ notificationItem.created_at }}
      <br>
      {{ notificationItem.created_at_to_string }}
    </v-card-subtitle>
    <v-card-text>
      <div v-html="notificationItem.content" />
    </v-card-text>
    <v-card-text v-if="notificationItem.data && notificationItem.data.nav">
      <v-btn
        v-if="notificationItem.data.nav.order_id"
        :to="{name: APP_ROUTES.order.index, query: {id: notificationItem.data.nav.order_id}}"
      >
        {{ $t('show') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

import { APP_ROUTES } from '@routes/list'

export default {
  name: 'NotificationView',
  props: {
    notification: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {
      btnKeys: [
        'customer_id',
        'order_id'
      ]
    }
  },
  computed: {
    notificationItem () {
      return this.notification || {}
    }
  },
  mounted () {
    this.markAsRead()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    navigateFromNotification (options) {
      const home = APP_ROUTES.user.home
      let i, key, id
      for (i in this.btnKeys) {
        key = this.btnKeys[i]
        id = options[key]
        // console.log(options, id, key)
        if (id) {
          const params = { datatableFilters: { id } }
          const name = this.$_.camelCase(key.substr(0, key.length - 3))
          // console.log(name)
          // console.log(this.$route, this.isRoute(name))
          if (this.isRoute(name)) {
            this.$router.push(this.route(home))
            this.$nextTick(() => {
              setTimeout(() => this.$router.replace(this.route(name, params)), 100)
            })
          } else {
            this.$router.push(this.route(name, params))
          }
          this.$nextTick(() => this.close())
          break
        }
      }
    },
    markAsRead () {
      if (this.notificationItem.unread === !0) {
        try {
          this.apiService.notification.markAsRead(this.notificationItem.id)
          this.UnreadAppNotifications = this.UnreadAppNotifications - 1
        } catch (e) {

        }
      }
    }
  }
}
</script>
