/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  data () {
    return {
      notificationDialogValue: !1,
      notificationDialogItem: undefined
    }
  },
  watch: {
    notificationDialogValue: {
      deep: !0,
      immediate: !0,
      handler (n) {
        n === !1 && (this.notificationDialogItem = undefined)
      }
    }
  },
  methods: {
    closeNotificationDialog () {
      this.notificationDialogValue = !1
    }
  }

}
