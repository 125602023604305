<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'Container'
}
</script>
