/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  name: 'Profile',
  photo: 'Profile Image',
  accountInformation: 'Account information',
  personalInformation: 'Personal information',
  security: 'Security'
}
