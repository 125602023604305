<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-text-field
              ref="input"
              :autocomplete="getAutocomplete"
              :clearable="getClearable"
              :counter="counter"
              :counter-value="a => a ? a.length : 0"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :hide-details="hideDetails"
              :label="getLabel"
              :myth-ltr="isEnglishName"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :reverse="isEnglishName"
              :value="value"
              v-bind="$attrs"
              v-on="$listeners"
            />
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>

import { InputsMixin } from '@mixins'

export default {
  name: 'TextInput',
  mixins: [InputsMixin],
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    }
  }
}
</script>
