/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Api from '@app/api'

export default {
  data () {
    return {
      controlLoading: !1
    }
  },
  methods: {
    inactiveUser (user, index) {
      if (this.controlLoading) return
      this.confirmMessage(this.$t('messages.inactive_user_confirm'), () => {
        this.controlLoading = !0
        Api.methods.user.inactiveUser(user.id).then(({ _success, _data, _message }) => {
          _message && this.alertSuccess(_message)
          _success && this.refreshDatatable(_data, index)
        }).catch(({ _message }) => {
          _message && this.alertError(_message)
        }).finally(() => (this.controlLoading = !1))
      })
    },
    activeUser (user, index) {
      if (this.controlLoading) return

      this.confirmMessage(this.$t('messages.active_user_confirm'), () => {
        this.controlLoading = !0
        Api.methods.user.activeUser(user.id).then(({ _success, _data, _message }) => {
          _message && this.alertSuccess(_message)
          _success && this.refreshDatatable(_data, index)
        }).catch(({ _message }) => {
          _message && this.alertError(_message)
        }).finally(() => (this.controlLoading = !1))
      })
    }
  }
}
