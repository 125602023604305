<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-textarea
              :auto-grow="autoGrow"
              :clearable="getClearable"
              :counter="counter"
              :counter-value="a => a ? a.length : 0"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :hide-details="hideDetails"
              :label="getLabel"
              :myth-ltr="isEnglishName"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :rows="rows"
              :value="value"
              v-bind="$attrs"
              v-on="{...$listeners,on:input}"
            />
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import TextInput from '@components/base/inputs/TextInput'

export default {
  name: 'TextareaInput',
  extends: TextInput,
  props: {
    autoGrow: {
      type: Boolean,
      default: () => !1
    },
    rows: {
      type: [String, Number],
      default: () => 2
    }
  }
}
</script>
