/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '@app/stub'
import axios from 'axios'

const url = StubUrl('Sales/Order')
const Static = StubUrl('Static/Sales/Order')
export default {
  ...Stub(url()),
  attributeLog: (id, attribute) => axios.get(url(`${id}/AttributeLog/${attribute}`)),
  publicStore: form => axios.post(Static(), form),
  updateNotes: (id, v) => axios.post(url(`${id}/UpdateNotes`), { order_notes: v }),
  assignOrders: ({ order_id, user_id }) => axios.post(url('AssignOrders'), { order_id, user_id })
}
