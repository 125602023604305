/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Vue from '@plugins/vue'
import _ from 'lodash'

_.mixin(require('lodash-inflection'))

Vue.prototype.$_ = _
window._ = _

export default _
