<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-text-input
    :counter="!1"
    :force-clearable="clearable"
    :name="name"
    :prepend-inner-icon="prependIcon"
    :rules="getRules"
    :value="value"
    readonly
    v-bind="$attrs"
    @click="click"
    v-on="$listeners"
    @click:prepend-inner="click"
  >
    <v-dialog
      ref="dialog"
      v-model="modal"
      :max-width="maxWidth"
      :return-value.sync="inputValue"
      :width="width"
      persistent
      v-bind="$attrs"
    >
      <v-date-picker
        v-model="inputValue"
        scrollable
        v-bind="$attrs"
      >
        <app-btn
          color="primary"
          text
          @click="save(inputValue)"
        >
          {{ $t('done') }}
        </app-btn>
        <v-spacer />
        <app-btn
          color="primary"
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </app-btn>
      </v-date-picker>
    </v-dialog>
  </app-text-input>
</template>

<script>

import TextInput from './TextInput'

export default {
  name: 'DatePicker',
  extends: TextInput,
  props: {
    maxWidth: {
      type: [String, Number],
      default: () => 290
    },
    width: {
      type: [String, Number],
      default: () => 290
    },
    prependIcon: {
      type: String,
      default: () => 'event'
    },
    clearable: {
      type: Boolean,
      default: () => !0
    },
    readonly: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      inputValue: undefined,
      modal: !1
    }
  },
  mounted () {
    this.inputValue = this.value
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    save (v) {
      this.$refs.dialog.save(v)
      this.input(v)
    },
    click () {
      if (this.readonly === !0) return
      this.modal = !this.modal
    },
    cancel () {
      this.modal = !1
    }
  }
}
</script>
