/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'
import { Tools } from '@helpers/Tools'

const appendArray = Tools.appendArray

export function Stub (prefix) {
  const url = p => prefix + (p ? `/${p}` : '')
  const methods = {
    index (params = {}, config = {}) {
      const u = url()
      return params === true ? u : axios.get(u, { ...config, params })
    },
    store (data = {}, config) {
      const u = url()
      const formData = new FormData()
      // for (let k in data)
      //   formData.append (k, data[k])
      appendArray(formData, data)
      return data === true ? u : axios.post(u, formData, config)

      // let u = url()
      // return args[0] === true ? u : axios.post(u, ...args)
    },
    show (id, ...args) {
      const u = url(id)
      return id === true ? u : axios.get(u, ...args)
    },
    update (id, data = {}, config = {}) {
      const u = url(id)
      const formData = new FormData()
      formData.append('_method', 'put')
      // console.log(data)
      appendArray(formData, data)
      return id === true ? u : axios.post(u, formData, config)
      // return id === true ? u : axios.put(u, ...args)
    },
    destroy (id, ...args) {
      const u = url(id)
      return id === true ? u : axios.delete(u, ...args)
    },
    utilities (params = {}, config = {}) {
      return methods.index({ ...params, itemsPerPage: -1 }, config)
    },
    staticUtilities (params = {}, config = {}) {
      const m = prefix.split('/').pop()
      const url = `Static/${m}`
      if (params === !0) {
        return url
      }

      params = params || {}
      params = {
        itemsPerPage: -1,
        ...params
      }
      return axios.get(url, { ...config, params })
    },
    uploadAttachments: (id, data, config) => axios.post(url(`${id}/Attachment/Upload`), data, config),
    deleteAttachment: (id, fileId) => axios.delete(url(`${id}/Attachment/${fileId}/Delete`))
  }
  return methods
}

export const StubUrl = url => (prefix = null) => url + (prefix ? `/${prefix}` : '')
