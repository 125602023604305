/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'

Vue.use(CKEditor)
Vue.prototype.$CKEditor = CKEditor
export default CKEditor
