<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-footer
    color="footer"
    padless
  >
    <v-card
      class="flex black--text"
      color="footer"
      flat
      tile
    >
      <v-card-title>
        <v-row>
          <v-col cols="auto">
            <v-img
              :src="AppIcon"
              contain
              height="70px"
              width="70px"
            />
          </v-col>
          <v-col>
            <strong class="subheading">{{ AppName }}</strong>
            <div
              class="text-subtitle-2"
              v-text="AppSettings.description"
            />
            <div
              class="text-subtitle-1"
              v-text="AppSettings.address"
            />
          </v-col>
        </v-row>
        <v-spacer />
        <v-col
          class="text-center"
          cols="12"
          md="auto"
        >
          <template v-if="AppSettings.socials && AppSettings.socials.length">
            <v-btn
              v-for="(link,iconIndex) in AppSettings.socials"
              :key="`icon-${iconIndex}`"
              :href="link.url"
              class="mx-4"
              icon
              target="_blank"
            >
              <v-icon
                color="black"
                size="25px"
              >
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </template>
          <div
            v-if="AppSettings.contact_mail"
            class="text-center black--text text-body-1"
          >
            <v-row
              align="center"
              dense
              justify="center"
            >
              <v-col
                class="ml-1"
                cols="auto"
              >
                <a
                  :href="`mailto:${AppSettings.contact_mail}`"
                  class="black--text"
                  v-text="AppSettings.contact_mail"
                />
              </v-col>
              <svg
                fill="none"
                height="20px"
                viewBox="-0.5 0 25 25"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="SVGRepo_bgCarrier"
                  stroke-width="0"
                />
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M22.0098 12.39V7.39001C22.0098 6.32915 21.5883 5.31167 20.8382 4.56152C20.0881 3.81138 19.0706 3.39001 18.0098 3.39001H6.00977C4.9489 3.39001 3.93148 3.81138 3.18134 4.56152C2.43119 5.31167 2.00977 6.32915 2.00977 7.39001V17.39C2.00977 18.4509 2.43119 19.4682 3.18134 20.2184C3.93148 20.9685 4.9489 21.39 6.00977 21.39H12.0098"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M21.209 5.41992C15.599 16.0599 8.39906 16.0499 2.78906 5.41992"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.0098 18.39H23.0098"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M20.0098 15.39L23.0098 18.39L20.0098 21.39"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </v-row>
          </div>
          <div class="">
            <div class="text-body-1">
              {{ $t('auth_by') }}
            </div>
            <v-img
              class="mx-auto"
              src="/assets/images/rega.png"
              width="150px"
            />
            <div class="text-body-1">
              {{ $t('auth_no', { n: '1200022543' }) }}
            </div>
          </div>
        </v-col>
      </v-card-title>
      <v-divider class="my-5" />
      <v-card-text
        class="py-2 text-center"
        style=""
      >
        {{ copyright }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { MetaInfoMixin } from '@mixins'

export default {
  name: 'MainFooter',
  mixins: [MetaInfoMixin],
  data: () => ({
    links: [
      {
        icon: 'mdi-facebook',
        url: '1'
      },
      {
        icon: 'mdi-twitter',
        url: '2'
      },
      {
        icon: 'mdi-linkedin',
        url: '3'
      },
      {
        icon: 'mdi-instagram',
        url: '4'
      }
    ]
  }),
  computed: {
    AppName () {
      return this.AppSettings.app_name || process.env.VUE_APP_NAME
    }
  },
  methods: {}
}
</script>

<style scoped>
/*.copyright{*/
/*  color: var(--v-footer-lighten5);*/
/*}*/
</style>
