/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Vue from 'vue'
import { AuthMixin, GlobalMixin, AppMixin, ThemeMixin, TrackEventsMixin, CurrencyMixin, SnackbarMixin } from '@mixins'
import { Tools } from '@helpers/Tools'
import Auth from '@helpers/Auth'
import Api from '@app/api'

require('./components')

window.Vue = Vue

Vue.mixin(GlobalMixin)
Vue.mixin(AppMixin)
Vue.mixin(ThemeMixin)
Vue.mixin(TrackEventsMixin)
Vue.mixin(AuthMixin)
Vue.mixin(SnackbarMixin)
Vue.mixin(CurrencyMixin)

Vue.prototype.$helpers = { ...Tools }
Vue.prototype.$auth = Auth
Vue.prototype.$api = Api

Vue.prototype.navigate = function (name, params = {}) {
  if (this.$route?.name !== name) {
    this.$router.push({ name, params })
  }
}

const requireComponent = require.context('./directives', true, /\.js$/)
requireComponent.keys().forEach((f) => requireComponent(f))
export default Vue
