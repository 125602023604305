<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<script>

import Combobox from '@components/base/inputs/Combobox'

export default {
  name: 'AttachmentTypes',
  extends: Combobox,
  computed: {
    getAxiosUrl () {
      return this.apiService.attachmentType.staticUtilities
    }
  }
}
</script>
