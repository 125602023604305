<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-progress-linear
    :active="$root.RootProgressBar"
    class="mb-1"
    color="primary"
    fixed
    indeterminate
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'ProgressLinear'
}
</script>
