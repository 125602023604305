/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'
import Auth from '@helpers/Auth'
import { Stub, StubUrl } from '@app/stub'

const URL = StubUrl('User')
const profile = StubUrl('Profile')

const Obj = {
  ...Stub(URL()),
  sideMenu: () => axios.get('get-side-menu'),
  updateAvatar (url = !1) {
    const a = URL('update-avatar')
    return url === !0 ? a : axios.post(a)
  },
  destroyAvatar (url = !1) {
    const a = URL('destroy-avatar')
    return url === !0 ? a : axios.delete(a)
  },
  profile: () => axios.get(profile()),
  refreshProfile () {
    return this.profile().then((response) => {
      const { data } = response || {}
      if (data && data.data && data.success === true) {
        Auth.save({ user: data.data })
      }
      return response
    })
  },
  updateAccount: form => axios.post(profile('Account'), form),
  updateProfile: form => axios.post(profile('Profile'), form),
  updatePassword: ({ password, password_confirmation }) => axios.post(profile('Password'), { password, password_confirmation }),
  updateLocale: locale => axios.post(profile('Locale'), { locale }),
  updateSignature: signature => axios.post(profile('Signature/update'), { signature }),
  destroyUserSignature: (id) => axios.delete(URL(`${id}/Signature/Destroy`)),
  activeUser: id => axios.get(URL(`${id}/Active`)),
  inactiveUser: id => axios.get(URL(`${id}/Inactive`)),
  dashboard: params => axios.get(URL('Dashboard'), { params })
}

export default Obj
