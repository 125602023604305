<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span
    v-bind="$attrs"
  >
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-combobox
              :append-icon="getAppendIcon"
              :clearable="getClearable"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :hide-details="hideDetails"
              :items="selectItems"
              :label="getLabel"
              :loading="loading"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :search-input.sync="search"
              :value="value"
              hide-selected
              v-bind="$attrs"
              v-on="$listeners"
            >
              <template
                v-for="slot in getSlots"
                #[slot]="props"
              >
                <slot
                  :name="slot"
                  v-bind="props"
                />
              </template>
              <template
                v-if="getSlots.indexOf('append-outer') !== -1"
                #prepend
              >
                <slot name="append-outer" />
              </template>
              <template #no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('comboboxNoData', { search }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import { InputsMixin } from '@mixins'

export default {
  name: 'Combobox',
  mixins: [InputsMixin],
  props: {
    appendIcon: {
      type: String,
      default: () => '$dropdown'
    }
  },
  data: () => ({
    loading: !1,
    search: null,
    items: []
  }),
  computed: {
    getAxiosUrl () {
      return null
    },
    getSlots () {
      return Object.keys(this.$scopedSlots).filter(i => ['top', 'bottom'].indexOf(i) < 0)
    },
    getAppendIcon () {
      return this.readonly === true ? null : this.appendIcon
    },
    selectItems: {
      get () {
        return this.items
      },
      set (v) {
        this.$emit('set-items', v)
      }
    }
  },
  mounted () {
    if (this.getAxiosUrl) {
      (async () => {
        if (this.loading) return
        this.loading = !0
        try {
          const { _data } = await this.getAxiosUrl()
          _data && (this.items = _data)
        } catch (e) {

        } finally {
          this.loading = !1
        }
      })()
    } else {
      if (this.$attrs.items) {
        this.items = this.$attrs.items
      }
    }
  },
  methods: {
    input (e) {
      const v = e && e?.name ? e.name : e
      this.$emit('input', v || null)
    },
    onClear (e) {
    }
  }
}
</script>
