/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  computed: {
    getHeaders () {
      return this.parseHeaders()
    }
  },
  methods: {
    parseHeaders (headers = null) {
      headers = (headers || this.headers || []).filter(e => Boolean(e))
      const _ = this.$_
      return headers.map((i) => {
        let key = _.isObject(i) ? i.text : i
        let c
        const a = ['ToString', '_to_string', '_to_yes']
        for (const b in a) {
          if (_.endsWith(key, (c = a[b]))) {
            key = key.substr(0, key.length - c.length)
            break
          }
        }

        // if (_.endsWith (key, (c = 'ToString'))) {
        //   key = key.substr (0, key.length - c.length)
        // } else if (_.endsWith (key, (c = '_to_string'))) {
        //   key = key.substr (0, key.length - c.length)
        // } else if (_.endsWith (key, (c = '_to_yes'))) {
        //   key = key.substr (0, key.length - c.length)
        // }

        const text = key ? this.parseAttribute(key) : key
        const value = _.isObject(i) ? i.value : i
        const original = _.isObject(i) ? { ...i } : {}
        const align = this.center === !0 ? 'center' : this.center
        const controlKey = this.controlKey || 'control'
        const controlWidth = this.controlWidth || '15%'
        // console.log(controlWidth)
        const itemKey = this.itemKey || 'id'
        const itemKeyWidth = this.itemKeyWidth || '6%'
        // console.log(this.controlKey)
        if (value === controlKey) {
          // console.log(original.width)
          original.width = controlWidth
          original.sortable = !1
          original.filterable = !1
          // original.class = {
          //
          //   ...(original.class||{}),
          // }
          original.class = 'd-print-none text-center'
          original.cellClass = 'd-print-none text-end'
          // original.align = original.align || 'end'
          // original.align = original.align || undefined
        } else if (value === itemKey) {
          original.width = itemKeyWidth
        }
        // original.divider = !1

        const header = { align, ...original, text, value }

        if (key === 'order_by') {
          header.width = header.width || '10%'
        }
        // console.log(header)
        return header
      })
    }
  }
}
