/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  landing_line: 'نقدم لك افضل الحلول التمويلية بأقساط ميسرة سجل الآن',
  send_order: 'إرسال الطلب'
}
