/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'
import { StubUrl } from '@app/stub'

const url = StubUrl('Static')

export default {
  search: (query) => axios.post(url('Search'), { query }),
  contactUs: (form, config = {}) => axios.post(url('Contact-Us'), form, config),
  setting: () => axios.get(url('PAS'))
}
