/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import config from '@app/config'
import Auth from '@helpers/Auth'
import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'
import api from '@app/api'

axios.defaults.baseURL = api.config.baseUrl
axios.defaults.paramsSerializer = params => Qs.stringify(params, { arrayFormat: 'indices' })

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['App-Locale'] = config.locale.defaultLocale
axios.defaults.headers.common['App-Name'] = api.config.name
axios.defaults.headers.common['App-Version'] = api.config.version
axios.defaults.headers.common.Accept = 'application/json'

// axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const accessToken = Auth.getAccessToken()
if (accessToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}
export const onFulfilled = function (response) {
  if (response) {
    response._data = response?.data?.data
    response._message = response?.data?.message
    response._meta = response?.data?.meta
    response._success = response?.data?.success || false
  }
  return response
}
export const onRejected = function (error) {
  const status = error?.response?.status
  // Not authorized
  if (status === 401) {
    // console.log(123)
    const logout = 'logout'
    if (window.location.pathname !== logout && window.location.pathname !== `/${logout}`) {
      window.location.href = `/${logout}`
    }
  }

  // No permissions
  if (status === 403) {
    // console.log(12)
    // if (window.location.pathname !== '/' && window.location.pathname !== '') {
    // window.location.href = '/'
    // }
  }

  if (error) {
    error._data = error?.response?.data?.data
    error._message = error?.response?.data?.message
    error._errors = error?.response?.data?.errors || {}
  }
  return Promise.reject(error)
}

axios.interceptors.response.use(onFulfilled, onRejected)

window.axios = axios
Vue.prototype.$axios = axios

export default axios
