<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-sheet
    color="transparent"
  >
    <v-container
      v-if="authUser"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="auto"
        >
          <p class="text-h5">
            {{ $t('replace.hello', { n: authUser.name }) }}
          </p>
          <app-btn :to="route(APP_ROUTES.order.index)">
            {{ $t('replace.go_to', { v: $tc('choice.Orders', 2) }) }}
          </app-btn>
        </v-col>
      </v-row>
      <template v-if="dashboardPermission">
        <public-statistics
          ref="publicReport"
          :print="print"
        />
      </template>
    </v-container>
  </v-sheet>
</template>

<script>
import PublicStatistics from '@components/home-user/PublicStatistics'

export default {
  name: 'Home',
  components: { PublicStatistics },
  data: () => ({}),
  computed: {
    dashboardPermission () {
      return this.userHasPermission('User.dashboard', !1)
    }
  },
  mounted () {
    if (!this.authUser && window && window.location.pathname !== '/') {
      window.location.href = '/'
    }
    // console.log(this.value)
    // if (this.authUser) {
    // }

    // if (!this.dashboardPermission) {
    //   const name = this.APP_ROUTES.homePage
    //   if (!this.isRoute(name)) {
    //     this.$router.replace({ name })
    //   }
    // }
  },
  methods: {
    print () {
      const publicReport = this.$refs.publicReport?.$el
      if (!publicReport) return

      publicReport.classList.add('d-print-none')
      window.print()
      publicReport.classList.remove('d-print-none')
    }
  }
}
</script>
