<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-system-bar
    :class="getClasses"
    :dark="themeLight"
    app
  >
    <span :class="spanClass">{{ day }}</span>
    <span :class="spanClass">{{ date }}</span>
    <span :class="spanClass">{{ hijri }}</span>
    <span>{{ time }}</span>
    <template v-if="!AppIsSmall">
      <v-spacer />
      <app-system-bar-btn @click="themeDark = !themeDark">
        <v-icon>mdi-palette</v-icon>
      </app-system-bar-btn>
      <app-system-bar-btn
        v-if="isLogin"
        @click="submitLogoutUser"
      >
        <v-icon>mdi-close</v-icon>
      </app-system-bar-btn>
    </template>
    <!--    <v-spacer></v-spacer>-->
    <!--    <span>{{ date }}</span>-->
    <!--    <v-spacer></v-spacer>-->
    <!--    <span>{{ hijri }}</span>-->
    <!--    <v-spacer></v-spacer>-->
  </v-system-bar>
</template>

<script>

import { Tools } from '@helpers/Tools'

export default {
  name: 'SystemBar',
  data () {
    return {
      hijri: null,
      date: null,
      time: null,
      day: null,
      spanClass: 'pe-2'
    }
  },
  computed: {
    getClasses () {
      return {
        primary: !0,
        'darken-2': this.themeLight,
        'darken-1': this.themeDark
      }
    }
  },
  created () {
    let time, date, day, hijri
    const ini = () => {
      time = this.$moment().format('hh:mm:ss a')
      date = this.$moment().format('YYYY/MM/DD')
      day = this.$moment().format('ddd')
      hijri = this.$moment().format('iYYYY/iMM/iDD')

      this.date = Tools.fromArabicNumber(`${date}`)
      this.hijri = Tools.fromArabicNumber(`${hijri}`)
      this.time = Tools.fromArabicNumber(time)
      this.day = Tools.fromArabicNumber(day)
    }
    ini()
    setInterval(ini, 1000)
  },
  methods: {
    closeWindow () {
      this.confirmMessage(this.$t('messages.sure_logout'), () => {
        this.logoutUser(!0)
      })
    }
  }
}
</script>

<style scoped></style>
