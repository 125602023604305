<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-autocomplete
              :append-icon="getAppendIcon"
              :clearable="getClearable"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :hide-details="hideDetails"
              :items="selectItems"
              :label="getLabel"
              :loading="loading"
              :multiple="multiple"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :single-line="singleLine"
              :value="value"
              v-bind="$attrs"
              v-on="{...$listeners,on:input}"
              @click:clear.stop="onClear"
            >
              <template
                v-for="slot in getSlots"
                #[slot]="props"
              >
                <slot
                  :name="slot"
                  v-bind="props"
                />
              </template>
              <template
                v-if="getSlots.indexOf('append-outer') !== -1"
                #prepend
              >
                <slot name="append-outer" />
              </template>
            </v-autocomplete>
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import { InputsMixin } from '@mixins'

export default {
  name: 'AutoSelect',
  mixins: [InputsMixin],
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: () => undefined
    },
    singleLine: {
      type: Boolean,
      default: () => undefined
    },
    nullText: {
      type: String,
      default: () => undefined
    },
    nullValue: {
      default: () => null
    },
    loading: {
      type: Boolean
    },
    appendIcon: {
      type: String,
      default: () => '$dropdown'
    }
  },
  computed: {
    getSlots () {
      return Object.keys(this.$scopedSlots).filter(i => ['top', 'bottom'].indexOf(i) < 0)
    },
    getAppendIcon () {
      return this.readonly === true ? null : this.appendIcon
    },
    selectItems: {
      get () {
        return this.items
      },
      set (v) {
        this.$emit('set-items', v)
      }
    }
  },
  methods: {
    input (e) {
      this.$emit('input', e || null)
    },
    onClear (e) {
    }
  }
}
</script>
